import { Component, HostListener, KeyValueDiffers, ViewChild, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import gitInfo from '../../git-version.json';
import { UserService } from '../services/user.service';
import { DefinitionService, EntityDefinition, UserActionType, DialogData, CraxSimpleDialogViewComponent, DialogResponseType } from '@craxit/crax-angular-core';
import { LayoutService } from '../services/layout-service';
import { CraxAuthService } from '@craxit/crax-angular-auth';
import { Params, Router } from '@angular/router';

interface LocalStorageQuery {
    key: string;
    entity: string;
    title: string;
    query: string;
    params: Params;
}

@Component({
    selector: 'bw-nav',
    templateUrl: './bw-nav.component.html',
    styleUrls: ['./bw-nav.component.scss']
})
export class BwNavComponent implements OnInit {
    // https://medium.com/poka-techblog/resolvers-angulars-one-click-data-delivery-b6fb2ab317e2
    navItems: EntityDefinition[] = [];

    // https://blog.eyas.sh/2018/12/data-and-page-content-refresh-patterns-in-angular/
    version = gitInfo.hash;

    // https://github.com/angular/material2/issues/2118 - workaround with the ngIf on mat-sidenav
    @ViewChild(MatSidenav, { read: false }) sidenav: MatSidenav;

    isHandset: boolean;
    userTryGetIsLoading: boolean;
    userTryGetIsNotFired = true;
    yourQueries: LocalStorageQuery[];
    staticPages: string[] = ['daily'];

    constructor(
        private router: Router,
        private definitionService: DefinitionService,
        private layoutService: LayoutService,
        public userService: UserService,
        public craxAuthService: CraxAuthService,
        private dialog: MatDialog
    ) { }

    public ngOnInit(): void {
        this.layoutService.isHandset$.subscribe(x => {
            this.isHandset = x;
        });

        this.userService.userTryGetIsLoading.subscribe(x => {
            this.userTryGetIsNotFired = false;
            this.userTryGetIsLoading = x;
            this.navItems = this.definitionService.getAllDefinitions().filter(def =>
                def.getShowInNav() ? this.userService.userHasPermissionFor(def, UserActionType.Read) : false
            );
            this.navItems = this.sortNavItems(this.navItems);
        });

        this.userService.checkTokenStatus();
        this.getYourQueries();
    }

    private sortNavItems(navItems: EntityDefinition[]): EntityDefinition[] {
        navItems = navItems.sort((def1: EntityDefinition, def2: EntityDefinition) => def1.getNavOrderIndex() - def2.getNavOrderIndex());
        return navItems;
    }

    onNavItemClicked(navItem: EntityDefinition) {
        if (this.staticPages.includes(navItem.getName())) {
            this.router.navigateByUrl(`/${navItem.getName()}`);
        } else {
            this.router.navigateByUrl(`/${navItem.getName()}/list`);
        }
    };

    logout() {
        const dialogConfig: MatDialogConfig<DialogData> = {
            width: '500px',
            data: {
                titleTxt: 'Uitloggen, bent u zeker',
                showConfirm: true,
                confirmTxt: 'Ja',
                cancelTxt: 'Nee',
            }
        };
        this.craxAuthService.logout();
    }

    @HostListener('window:storage', ['$event'])
    getYourQueries(): void {
        this.yourQueries = Object.keys(localStorage)
            .filter((key: string) => key.includes('savedquery:'))
            .map((key: string) => {
                const entity: string = key
                    .replace('savedquery:', '')
                    .split('_')[0];
                return {
                    key,
                    entity,
                    title: key.replace(`savedquery:${entity}_`, ''),
                    query: localStorage.getItem(key),
                    params: this.formatQueryParams(localStorage.getItem(key))
                };
            });
    }

    formatQueryParams(query: string): Params {
        let params: Params = {};
        const queryparams = query.split('?')[1].split('&');

        queryparams.forEach(x => params[x.split('=')[0]] = decodeURIComponent(x.split('=')[1]));
        return params;
    }

    removeQuery(event: Event, query: LocalStorageQuery): void {
        event.stopPropagation();
        localStorage.removeItem(query.key);
        window.dispatchEvent(new Event('storage'));
    }
}
