import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Client, UserVM, PermissionOption } from '../Http/HttpClients';
import { Subject } from 'rxjs';
import { EntityDefinition, PermissionOptions, UserActionType } from '@craxit/crax-angular-core';
import { CraxAuthService } from '@craxit/crax-angular-auth';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  get userRegistered(): boolean {
    return this.loggedInUser !== null;
  }
  get userName(): string {
    return this.craxAuthService.getClaims().email;
  }
  get userId(): number {
    return this.loggedInUser !== null ? this.loggedInUser.id : -1;
  }

  loggedInUser: UserVM = null;
  userTryGetIsLoading: Subject<boolean> = new Subject();

  constructor(
    private httpClient: Client, private router: Router, private craxAuthService: CraxAuthService) {
  }

  async checkTokenStatus(): Promise<void> {
    const user = await this.craxAuthService.getUser();
    if (this.craxAuthService.isLoggedIn()) {
      this.userTryGet();
    }
  }

  userTryGet(): void {
    this.userTryGetIsLoading.next(true);
    this.httpClient.apiUserTryget()
      .subscribe(userVm => {
        const isRegistered = userVm !== null;
        this.loggedInUser = userVm;
        if (!isRegistered) {
          this.router.navigateByUrl('/register');
        } else {
          if (this.router.url === '/' || this.router.url === '/login' || this.router.url === '/register') {
            // this.router.navigateByUrl('/company/list');
          }
        }
        this.userTryGetIsLoading.next(false);
      });
  }

  userHasPermissionFor(def: EntityDefinition, action: UserActionType): boolean {
    //vacature, create
    if (def.getPermissionOptions() === undefined || def.getPermissionOptions().length === 0) { return true; }

    let userRoles = this.getCurrentUserRoles(); // [ACMA, Recruiting]

    if (userRoles.includes("Administrator")) {
      return true;
    }

    for (const userRole of userRoles) {
      const permissionForUserRole = def.getPermissionOptions().find(x => x.userClaims.includes(userRole) && x.actionNames.includes(action));
      if (permissionForUserRole !== undefined) {
        return true;
      }
    }
    return false;
  }

  private getCurrentUserRoles(): string[] {
    let userRoles: string[] = [];
    if (this.craxAuthService.getClaims()?.role) {
      userRoles = typeof this.craxAuthService.getClaims().role === 'string' ? [this.craxAuthService.getClaims().role] : this.craxAuthService.getClaims().role; // Users can have multiple roles
    }
    return userRoles;
  }

}
