import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpErrorResponse, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Entity, LoggingService } from '@craxit/crax-angular-core';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private loggingService: LoggingService) { }

    intercept(request: HttpRequest<Entity>, next: HttpHandler): Observable<HttpEvent<Entity>> {
        // tslint:disable-next-line:no-any
        return next.handle(request).pipe(tap(() => { }, async (err: any) => {
            if (err instanceof HttpErrorResponse) {
                let response = new HttpErrorResponse({
                    error: await err.error.text(),
                    headers: err.headers,
                    status: err.status,
                    statusText: err.statusText,
                    url: err.url
                });
                this.loggingService.logHttpError(response);
            }
        }));
    }
}
