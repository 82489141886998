import { Component, OnInit, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { CalendarView, collapseAnimation } from 'angular-calendar';
import { ViewPeriod, CalendarEvent, EventColor } from 'calendar-utils';
import * as moment from 'moment-timezone';
import RRule, { Frequency, ByWeekday, Weekday } from 'rrule';
import { Subject } from 'rxjs';

import { DefinitionService, PropertyTypes, Entity, EntityDefinition, StringHelperService, LoggingService, CraxAbstractHttpClient, CraxDialogService, DialogResponseType, UserActionType } from '@craxit/crax-angular-core';

import { DoorbirdRecurringEventRecurringType } from '../Http/HttpClients';

import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import endOfDay from 'date-fns/endOfDay';

import { IDialogData } from '../models/calendar/CalendarDialog';

import { BwCalendarDialogComponent } from './bw-calendar-dialog/bw-calendar-dialog.component';
import { UserService } from '../services/user.service';

export interface EventMeta {
  entity: Entity;
  entityDefinition: EntityDefinition;
}

@Component({
  selector: 'bw-calendar',
  templateUrl: './bw-calendar.component.html',
  styleUrls: ['./bw-calendar.component.scss'],
  animations: [collapseAnimation]
})
export class BwCalendarComponent implements OnInit {
  @Input()
  entity: Entity;
  @Input()
  entityId: number;
  @Input()
  entityName: string;

  CalendarView = CalendarView;
  viewPeriod: ViewPeriod;
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  locale = 'nl-BE';
  refresh: Subject<void> = new Subject();

  calendarEvents: CalendarEvent<EventMeta>[] = [];
  pristineMetaEvents: EventMeta[] = [];

  entityDefinition: EntityDefinition;
  calendarEntityDefinitions: EntityDefinition[] = [];

  activeFilters: string[] = [];
  activeDayIsOpen = false;

  dayStartHour = 8;
  dayEndHour = 20;

  getEnd = {
    month: endOfMonth,
    week: endOfWeek,
    day: endOfDay
  }[this.view];

  constructor(public dialog: MatDialog, private definitionService: DefinitionService, private dialogService: CraxDialogService, private crudDataService: CraxAbstractHttpClient, private loggingService: LoggingService, private stringHelperService: StringHelperService, private userService: UserService) { }

  ngOnInit(): void {
    this.entityDefinition = this.definitionService.getEntityDefinitionByName(this.entityName);
    this.initCalendarEntities();
    this.sortEventsByHour();
    this.pristineMetaEvents = Array.from(new Set(this.calendarEvents.map(x => x.meta)));
    this.refresh.next();
  }

  permittedCalendarEvents(entityDefinitions: EntityDefinition[]): EntityDefinition[] {
    return entityDefinitions.filter(def => this.userService.userHasPermissionFor(def, UserActionType.Create));
  }

  initCalendarEntities(): void {
    this.entityDefinition.properties.filter(x => x.type === PropertyTypes.CalendarObjectType).forEach(propertyDefinition => {
      const calendarEntityDefinition = this.definitionService.getEntityDefinitionByName(propertyDefinition.formOptions.ReferenceEntityName);
      if (calendarEntityDefinition.getCalendarOptions()) {
        this.calendarEntityDefinitions.push(calendarEntityDefinition);
        this.addEventsToCalendar(calendarEntityDefinition, this.entity[propertyDefinition.PropertyName]);

      } else {
        throw new Error(`You forgot to add calendarOptions for: ${PropertyTypes.CalendarObjectType.toString()}.
        RootEntityName: ${this.entityName} PropertyNameOfRootEntity: ${propertyDefinition.PropertyName} EntityNameWithMissingCalendarOptions: ${calendarEntityDefinition.getName()}`);
      }
    });

  }

  toggleEntityOff(name: string): void {
    this.activeFilters = this.activeFilters.filter(x => x !== name);
    this.refreshCalendar();
  }

  toggleEntityOn(name: string): void {
    this.activeFilters.push(name);
    this.refreshCalendar();
  }

  monthCellViewIsEventCompletedOrPassed(event: CalendarEvent<EventMeta>): boolean {
    if (event.meta.entityDefinition.getCalendarOptions()) {
      return event.meta.entity[event.meta.entityDefinition.getCalendarOptions().isCompletedPropertyName];
    }
    return false;
  }

  addEventsToCalendar(entityDefinition: EntityDefinition, entities: Entity[]): void {
    entities.forEach(propertyData => {
      this.addEventToCalendar({ entity: propertyData, entityDefinition });
    });
  }

  addEventToCalendar(eventMeta: EventMeta): void {
    if (!this.activeFilters.includes(eventMeta.entityDefinition.getName())) {
      if (eventMeta.entityDefinition.getCalendarOptions().recurringOptions) {
        this.createAndAddRecurringEventToCalendar(eventMeta);
      } else {
        this.createAndAddSingleEventToCalendar(eventMeta);
      }
    }
  }

  sortEventsByHour(): void {
    this.calendarEvents = this.calendarEvents.sort((a, b) => {
      if (a.start > b.start) { return 1; }
      if (a.start < b.start) { return -1; }
      return 0;
    });
  }

  refreshCalendar(): void {
    this.calendarEvents = [];
    this.pristineMetaEvents.forEach(x => this.addEventToCalendar(x));
    this.sortEventsByHour();
    this.refresh.next();
  }

  calendarViewChanged(): void {
    this.activeDayIsOpen = false;
    this.refreshCalendar();
  }

  createAndAddSingleEventToCalendar(eventMeta: EventMeta): void {
    const dateForCalendar = eventMeta.entity[eventMeta.entityDefinition.getCalendarOptions().datePropertyName];
    this.createEventObject(eventMeta, moment(dateForCalendar));
  }

  createAndAddRecurringEventToCalendar(eventMeta: EventMeta): void {
    const currentOffset = moment(eventMeta.entity.date).utcOffset();
    this.createRRule(eventMeta).all().forEach(recurringSingleStartDate => {
      this.createEventObject(eventMeta, moment(recurringSingleStartDate).utcOffset(currentOffset));
    });
  }

  createRRule(eventMeta: EventMeta): RRule {
    const startDate = eventMeta.entity[eventMeta.entityDefinition.getCalendarOptions().datePropertyName];
    const frequencyForCalendar = eventMeta.entity[eventMeta.entityDefinition.getCalendarOptions().recurringOptions.frequencyPropertyName];
    const untilDate = eventMeta.entity[eventMeta.entityDefinition.getCalendarOptions().recurringOptions.untilDatePropertyName];
    const currentEndDateOfView = moment(this.getEnd(this.viewDate)).endOf('day');

    return new RRule({
      dtstart: startDate.toDate(),
      freq: this.getRecurringFrequency(frequencyForCalendar),
      byweekday: this.getAllowedDaysByRecurringType(frequencyForCalendar, startDate),
      wkst: RRule.MO,
      until: untilDate < currentEndDateOfView ? untilDate.toDate() : currentEndDateOfView.toDate()
    });
  }

  createEventObject(eventMeta: EventMeta, startDate: moment.Moment): void {
    const timeSpan = (eventMeta.entity[eventMeta.entityDefinition.getCalendarOptions().timeSpanPropertyName] || '01:00:00').split(':');
    const displayValueObject = { ...eventMeta.entity, date: startDate, };
    this.calendarEvents.push({
      meta: eventMeta,
      color: this.getColorsForEventObject(eventMeta),
      title: this.stringHelperService.formatModelForDisplay(displayValueObject, eventMeta.entityDefinition.getCalendarOptions().eventDisplay),
      start: startDate.toDate(),
      end: moment(startDate).add(parseInt(timeSpan[0], 10), 'hours').add(parseInt(timeSpan[1], 10), 'minutes').add(parseInt(timeSpan[2], 10), 'seconds').toDate(),
    });
  }

  getColorsForEventObject(eventMeta: EventMeta): EventColor {
    if (eventMeta.entityDefinition.getCalendarOptions() && eventMeta.entity[eventMeta.entityDefinition.getCalendarOptions().isCompletedPropertyName]) {
      return { primary: '#ccc', secondary: '#ccc' };
    }
    return { primary: '#f00', secondary: '#f00' };
  }

  addEvent(date: Date, propertyDefinition: EntityDefinition): void {
    this.openCreateDialog(propertyDefinition, date);
  }

  eventClicked(event: { event: CalendarEvent<EventMeta> }): void {
    // this.crudDataService.Get(event.event.meta.entityDefinition, event.event.meta.entity.id, "Company,Contact,User").subscribe(x => {
    //   event.event.meta.entity = x;
    this.openEditDialog(event.event.meta);

    // });
  }

  deleteEventConfirmed(event: CalendarEvent<EventMeta>): void {
    const dialogData = this.dialogService.createDialogData("Are you sure you want to delete this event?", true, "Yes", "No");
    const dialogRef = this.dialogService.openSimpleDialog(dialogData);
    dialogRef.afterClosed().subscribe(result => {
      if (result === DialogResponseType.Confirm) {
        this.crudDataService
          .Delete(event.meta.entityDefinition, event.meta.entity.id)
          .subscribe(() => {
            this.pristineMetaEvents = this.pristineMetaEvents.filter(x => x !== event.meta);
            this.refreshCalendar();
            this.loggingService.logMessage('Event was deleted', 'Success!');
            if (this.calendarEvents.find(calendarEvent => moment(calendarEvent.start).isSame(event.start, 'day')) === undefined) {
              this.activeDayIsOpen = false;
            }
          });
      }
    })
  }

  openCreateDialog(entityDefinition: EntityDefinition, date: Date): void {
    const mdate = moment(date);

    if (mdate.hour() === 0) {
      mdate.hour(8);
    }
    const dialogConfig: MatDialogConfig<IDialogData> = {
      width: '500px',
      data: {
        entityDefinition: entityDefinition,
        updateDateAfterIsLoaded: {
          date: moment(date),
        },
        parentId: this.entityId,
      }
    };

    const dialogRef: MatDialogRef<BwCalendarDialogComponent, EventMeta> = this.dialog.open(BwCalendarDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response: EventMeta) => {
      if (response) {
        this.pristineMetaEvents.push(response);
        this.refreshCalendar();
      }
    });
  }

  openEditDialog(eventMeta: EventMeta): void {
    const entityDefinition = eventMeta.entityDefinition;
    const dialogConfig: MatDialogConfig<IDialogData> = {
      width: '500px',
      data: {
        entityDefinition: entityDefinition,
        existingEntity: eventMeta.entity,
        parentId: this.entityId,
      }
    };
    const dialogRef: MatDialogRef<BwCalendarDialogComponent, EventMeta> = this.dialog.open(BwCalendarDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((response: EventMeta) => {
      if (response) {
        this.pristineMetaEvents = this.pristineMetaEvents.map(x => (x.entity.id === response.entity.id && x.entityDefinition.getName() === response.entityDefinition.getName()) ? response : x);
        this.refreshCalendar();
      }
    });
  }

  toggleEventListsInMonthView({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (moment(date).isSame(this.viewDate, 'month')) {
      if (moment(this.viewDate).isSame(date, 'day') && this.activeDayIsOpen !== false) {
        this.activeDayIsOpen = false;
      } else {
        this.viewDate = date;
        if (events.length > 0) {
          this.activeDayIsOpen = true;
        } else {
          this.activeDayIsOpen = false;
        }
      }
    }
  }

  getRecurringFrequency(recurringType: DoorbirdRecurringEventRecurringType): Frequency {
    switch (recurringType) {
      case DoorbirdRecurringEventRecurringType.Monthly:
        return Frequency.MONTHLY;
      case DoorbirdRecurringEventRecurringType.Weekly:
        return Frequency.WEEKLY;
      case DoorbirdRecurringEventRecurringType.Daily:
        return Frequency.DAILY;
      case DoorbirdRecurringEventRecurringType.DailyWorkDay:
      default:
        return Frequency.WEEKLY;
    }
  }

  getAllowedDaysByRecurringType(recurringType: DoorbirdRecurringEventRecurringType, startDate: moment.Moment): Weekday[] {
    let weekdays: Weekday[];
    switch (recurringType) {
      case DoorbirdRecurringEventRecurringType.Daily:
        weekdays = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR, RRule.SA, RRule.SU];
        break;
      case DoorbirdRecurringEventRecurringType.Weekly:
      case DoorbirdRecurringEventRecurringType.Monthly:
        weekdays = [new Weekday(startDate.day() - 1)];
        break;
      case DoorbirdRecurringEventRecurringType.DailyWorkDay:
        weekdays = [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR];
        break;
      case DoorbirdRecurringEventRecurringType.DailyWorkDayWithHomeWorkDay:
        weekdays = [RRule.MO, RRule.TU, RRule.TH, RRule.FR];
        break;
      default:
        throw new Error('RecurringType has not yet been added.');
    }
    return weekdays;
  }
}
