<form class="form">
    <h3 class="title">Nieuwe Kandidaat actie</h3>
    <mat-form-field class="group">
        <mat-label>Kandidaat Status</mat-label>
        <mat-select name="candidateStatus" [(ngModel)]="candidateActionForm.candidateStatus"
            (selectionChange)="onCandidateStatusChanged()">
            <mat-option *ngFor="let option of candidateStatusTypeOptions" [value]="option">
                {{ getDisplayOfEnum('candidate-status', option) }}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field class="group">
        <mat-label>Actie Type</mat-label>
        <mat-select name="candidateType" [(ngModel)]="candidateActionForm.type">
            <mat-option *ngFor="let option of actionTypeOptions" [value]="option">
                {{ getDisplayOfEnum('candidate-type', option) }}
            </mat-option>
        </mat-select>
    </mat-form-field>


    <div class="flex">
        <mat-form-field class="group">
            <mat-label>Datum</mat-label>
            <input type="date" matInput class="long-input" [(ngModel)]="candidateActionForm.date" name="date">
        </mat-form-field>
        <div class="flex">
            <mat-form-field class="small-input">
                <mat-label>Uur</mat-label>
                <input matInput class="long-input" [(ngModel)]="candidateActionForm.hours" name="hours">
            </mat-form-field>
            :
            <mat-form-field class="small-input">
                <mat-label>Min</mat-label>
                <input matInput class="long-input" [(ngModel)]="candidateActionForm.minutes" name="minutes">
            </mat-form-field>
        </div>
    </div>

    <mat-form-field class="group">
        <mat-label>Titel</mat-label>
        <input matInput class="long-input" [(ngModel)]="candidateActionForm.title" name="title">
    </mat-form-field>

    <mat-form-field class="group">
        <mat-label>Beschrijving</mat-label>
        <textarea matInput class="long-input" [(ngModel)]="candidateActionForm.description"
            name="description"></textarea>
    </mat-form-field>

    <div class="middle group">
        <button class="button reverse-button" (click)="onCancel()">Annuleren</button>
        <button class="button" (click)="onSubmitForm()">Opslaan</button>
    </div>
</form>