import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { EntityType, CandidateStatusType } from '../Http/HttpClients';
import { format } from 'date-fns';
import moment from 'moment';
import { EnumValue } from '../static-pages/daily-page.component';

@Component({
    selector: 'candidate-action',
    templateUrl: './candidate-action.component.html',
    styleUrls: ['./candidate-action.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CandidateActionComponent implements OnInit {
    constructor() { }

    candidateStatusTypeOptions: string[];
    actionTypeOptions: string[];
    candidateActionForm: CandidateActionForm;

    @Input() candidateStatusEnumValues: EnumValue[];
    @Input() candidateTypeEnumValues: EnumValue[];
    @Input() candidateId: number;
    @Input() candidateStatus: CandidateStatusType;
    @Output() onFormSubmitted = new EventEmitter<DailyPageDto | null>();

    ngOnInit(): void {
        const currDate = new Date();

        this.candidateStatusTypeOptions = Object.values(CandidateStatusType);
        this.actionTypeOptions = Object.values(EntityType);

        this.candidateActionForm = {
            candidateId: this.candidateId,
            date: format(currDate, 'yyyy-MM-dd'),
            hours: currDate.getHours(),
            minutes: currDate.getMinutes(),
            description: '',
            title: '',
            candidateStatus: this.candidateStatus,
            type: null,
        }
    }

    onCandidateStatusChanged(): void {
        this.candidateActionForm = {
            ...this.candidateActionForm,
            title: `Nieuwe status naar ${this.getDisplayOfEnum('candidate-status', this.candidateActionForm.candidateStatus)}`
        }
    }

    onSubmitForm(): void {
        const { candidateId, candidateStatus, date, description, hours, minutes, title, type } = this.candidateActionForm;
        const dateString = `${date} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        this.onFormSubmitted.emit({
            candidateAction: {
                candidateId,
                description,
                title,
                type,
                date: moment(dateString),
            },
            candidateStatus,
        });
    }

    getDisplayOfEnum(enumType: EnumType, value: string): string {
        const enumValues: EnumValue[] = enumType === 'candidate-status' ? this.candidateStatusEnumValues : this.candidateTypeEnumValues;
        return enumValues.find(x => x.value.toLowerCase() === value.toLowerCase())?.display;
    }

    onCancel(): void {
        this.onFormSubmitted.emit(null);
    }
}

export type CandidateActionForm = {
    candidateStatus: CandidateStatusType,
    title: string;
    description: string;
    date: string,
    hours: number;
    minutes: number;
    type: EntityType | null;
    candidateId: number;
}

export type CandidateActionDto = Omit<CandidateActionForm, 'hours' | 'minutes' | 'date' | 'candidateStatus'> & { date: moment.Moment };

export type DailyPageDto = {
    candidateAction: CandidateActionDto;
    candidateStatus: CandidateStatusType;
}

export type EnumType = 'candidate-status' | 'candidate-type';