<div class="flex">
    <div class="data">
        <p>
            <span *ngIf="data[1] ? data[1].showAll : true">
                <h4>ERROR!</h4>
                {{ data[0].status }} - {{ responseText }}
            </span>
            <br /><br />
            <span *ngIf="data[1] ? data[1].showAll : true">
                <strong>Url: </strong><em>{{ data[0].url }}</em>
            </span>
            <br />
        </p>
        <hr />
        <p>
            <span *ngIf="data[1] ? data[1].showAll : true">
                <h5>Full error-message:</h5>
                <em>"{{ data[0].message}}"</em>
            </span>
            <br />
        </p>
        <hr />
    </div>
    <div class="dismiss">
        <button mat-icon-button (click)="snackBarRef.dismiss()">
            close <mat-icon>close</mat-icon>
        </button>
    </div>
</div>