import { Injectable } from '@angular/core';
import { EntityDefinitionModel, PropertyDefinition, ViewEntityDefinitionModel, ButtonOption as HttpButtonOption } from '../Http/HttpClients';
import { EntityDefinition, HiddenPropertyDefinition, EntityPropertyModel, propertyTypes, TextPropertyDefinition, ButtonOption, PropertyDefinitionFactoryService } from '@craxit/crax-angular-core';
@Injectable({
  providedIn: 'root'
})
export class DefinitionHttpService {

  constructor(private propertyFactory: PropertyDefinitionFactoryService) { }


  mapViewEntity(viewEntityDefinitionModel: ViewEntityDefinitionModel, sourceEntityDefinition: EntityDefinitionModel): EntityDefinition {
    viewEntityDefinitionModel = new ViewEntityDefinitionModel({ ...sourceEntityDefinition, ...viewEntityDefinitionModel });
    this.copyPropertiesFromSourceDefinition(viewEntityDefinitionModel, sourceEntityDefinition);

    const newEntityDefinition = new EntityDefinition(
      viewEntityDefinitionModel.name,
      viewEntityDefinitionModel.showInNav,
      viewEntityDefinitionModel.navOrderIndex,
      viewEntityDefinitionModel.pluralTitle == null ? viewEntityDefinitionModel.name : viewEntityDefinitionModel.pluralTitle,
      viewEntityDefinitionModel.singleTitle,
      viewEntityDefinitionModel.tableOrderBy,
      viewEntityDefinitionModel.definitionHttpOptions,
      viewEntityDefinitionModel.calendarOptions,
      this.convertButtonOptions(viewEntityDefinitionModel.buttonOptions),
      viewEntityDefinitionModel.permissionOptions
    );
    this.addPropertiesFromModelToDefinition(newEntityDefinition, viewEntityDefinitionModel);
    return newEntityDefinition;
  }

  mapEntity(inputEntity: EntityDefinitionModel): EntityDefinition {
    const newEntityDefinition = new EntityDefinition(
      inputEntity.name,
      inputEntity.showInNav,
      inputEntity.navOrderIndex,
      inputEntity.pluralTitle == null ? inputEntity.name : inputEntity.pluralTitle,
      inputEntity.singleTitle,
      inputEntity.tableOrderBy,
      inputEntity.definitionHttpOptions,
      inputEntity.calendarOptions,
      this.convertButtonOptions(inputEntity.buttonOptions),
      inputEntity.permissionOptions
    );
    this.addPropertiesFromModelToDefinition(newEntityDefinition, inputEntity);

    return newEntityDefinition;
  }

  private copyPropertiesFromSourceDefinition(viewDefinition: ViewEntityDefinitionModel, sourceDefinition: EntityDefinitionModel) {
    viewDefinition.properties = viewDefinition.properties.map((propVM: PropertyDefinition) => {
      const sourceProperty: PropertyDefinition = sourceDefinition.properties.find(sourceProp => sourceProp.propertyName.toLowerCase() === propVM.propertyName.toLowerCase());
      if (sourceProperty) {
        return new PropertyDefinition({ ...sourceProperty, ...propVM });
      }
      return propVM;
    });


    viewDefinition.buttonOptions = viewDefinition.buttonOptions ? viewDefinition.buttonOptions : sourceDefinition.buttonOptions;
    //permissionOptions 
    //calendarOptions

  }

  private addPropertiesFromModelToDefinition(entityDefintion: EntityDefinition, entityDefinitionModel: EntityDefinitionModel | ViewEntityDefinitionModel): void {
    this.propertyFactory.AddDefaultProperties(entityDefintion);
    entityDefinitionModel.properties.forEach(property => {
      if (property.propertyName !== 'version') { // nog uit de definitions backend halen dat deze niet wordt aangemaakt
        const propertyDefinitionClass = this.propertyFactory.propertyTypesMap.get(property.type) || TextPropertyDefinition;
        const propertyDefinition = new propertyDefinitionClass(this.convertToEntityPropertyModel(property));
        if (property.objectOptions && property.objectOptions.idName) {
          entityDefintion.properties.push(new HiddenPropertyDefinition({ propertyName: property.objectOptions.idName, type: 'hidden' }));
        }
        entityDefintion.properties.push(propertyDefinition);
      }
    })
  };

  private convertButtonOptions(HttpButtonOption: HttpButtonOption[]): ButtonOption[] {
    let buttonArray = new Array<ButtonOption>();
    HttpButtonOption.forEach(x => {
      let buttonOpt: ButtonOption = {
        text: x.text,
        color: x.color,
        size: x.size,
        validator: x.validator,
        function: x.function,
        type: (x.type as 'action' | 'url'),
        location: (x.location as 'bottom' | 'title')
      }
      buttonArray.push(buttonOpt);
    })
    return buttonArray;
  }

  private convertToEntityPropertyModel(inputProp: PropertyDefinition): EntityPropertyModel {
    const propertyModel = new EntityPropertyModel();
    propertyModel.shared = inputProp.shared;
    propertyModel.propertyName = inputProp.propertyName;
    propertyModel.tableOptions = inputProp.tableOptions;
    propertyModel.referenceOptions = inputProp.referenceOptions;
    propertyModel.readonly = inputProp.readonly;
    propertyModel.formOptions = inputProp.formOptions;
    propertyModel.titleOptions = inputProp.titleOptions ? { label: inputProp.titleOptions.label, size: inputProp.titleOptions.size as ('small' | 'medium' | 'big'), underline: inputProp.titleOptions.underline } : undefined;
    propertyModel.type = inputProp.type as propertyTypes; // might not be save ?
    propertyModel.enumOptions = inputProp.enumOptions;
    propertyModel.objectOptions = inputProp.objectOptions;
    propertyModel.manyOptions = inputProp.manyOptions;
    propertyModel.autocompleteOptions = inputProp.autoCompleteOptions;
    propertyModel.autoChipsOptions = inputProp.autoChipsOptions;
    propertyModel.fileOptions = inputProp.fileOptions;
    propertyModel.textOptions = inputProp.textOptions;
    propertyModel.dateOptions = inputProp.dateOptions;
    return propertyModel;
  }
}
