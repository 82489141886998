import { Component } from '@angular/core';
import { UserService } from '../services/user.service';
import { CraxAuthService as AuthService } from '@craxit/crax-angular-auth';

@Component({
  selector: 'bw-login',
  templateUrl: './bw-login.component.html',
  styleUrls: ['./bw-login.component.scss']
})
export class BwLoginComponent {

  constructor(public userService: UserService,
    public authService: AuthService) { }
}
