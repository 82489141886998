import { Component } from '@angular/core';
import { PageHelperService } from '@craxit/crax-angular-core';
import { Router } from '@angular/router';

@Component({
  selector: 'bw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Let\'s BookIT!';

  constructor(pageHelperService: PageHelperService, router: Router) {
    pageHelperService.init(router);
  }
}
