<div class="dark-overlay-wrapper" *ngIf="selectedCandidate != null">
    <div class="dark-overlay">
        <div class="overlay">
            <candidate-action [candidateId]="selectedCandidate.candidateId" [candidateStatus]="selectedCandidate.status"
                [candidateStatusEnumValues]="candidateStatusEnumValues"
                [candidateTypeEnumValues]="candidateTypeEnumValues"
                (onFormSubmitted)="onFormSubmitted($event)"></candidate-action>
        </div>
    </div>
</div>

<div *ngIf="(onGoingCandidates$ | async) as onGoingCandidates">
    <crax-simple-table [title]="'1ste gesprek'" [entityName]="'candidate'" [tableData]="onGoingCandidates.value"
        [displayedColumns]="displayedColumns" [enumValues]="candidateStatusEnumValues"
        [pageSize]="onGoingCandidatesPagination.take" [pageIndex]="onGoingCandidatesPagination.pageIndex"
        [dataCount]="onGoingCandidates['@odata.count']"
        (paginatorEvent)="onPaginatorChanged('ongoing-candidate', $event)"
        (rowClickedEvent)="onRowClicked($event)"></crax-simple-table>
</div>

<div class="spacer"></div>

<div *ngIf="(hotCandidates$ | async) as hotCandidates">
    <crax-simple-table [title]="'2de gesprek'" [enumValues]="candidateStatusEnumValues" [entityName]="'candidate'"
        [tableData]="hotCandidates.value" [displayedColumns]="displayedColumns"
        [pageSize]="hotCandidatesPagination.take" [pageIndex]="hotCandidatesPagination.skip"
        [dataCount]="hotCandidates['@odata.count']" (paginatorEvent)="onPaginatorChanged('hot-candidate', $event)"
        (rowClickedEvent)="onRowClicked($event)"></crax-simple-table>
</div>

<div class="spacer"></div>

<div *ngIf="(openCandidates$ | async) as openCandidates">
    <crax-simple-table [title]="'Op te volgen'" [enumValues]="candidateStatusEnumValues" [entityName]="'candidate'"
        [tableData]="openCandidates.value" [displayedColumns]="displayedColumns"
        [pageSize]="openCandidatesPagination.take" [pageIndex]="openCandidatesPagination.skip"
        [dataCount]="openCandidates['@odata.count']" (paginatorEvent)="onPaginatorChanged('open-candidate', $event)"
        (rowClickedEvent)="onRowClicked($event)"></crax-simple-table>
</div>