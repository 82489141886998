import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoggingService } from '@craxit/crax-angular-core';
import { HttpErrorResponse } from '@angular/common/http';
import { HttpErrorComponent } from '../http-error/http-error.component';

@Injectable({
    providedIn: 'root'
})
export class SnackBarService extends LoggingService {


    constructor(private snackBar: MatSnackBar) {
        super();
    }

    logMessage(message: string, action: string): void {
        this.snackBar.open(message, action, {
            verticalPosition: 'top',
            duration: 2500,
        });
    }

    logError(message: string): void {
        this.snackBar.open(message, 'Error', {
            verticalPosition: 'top',
        });
    }

    logHttpError(httpError: HttpErrorResponse): void {
        console.error('httpErrorSnackBar');

        this.snackBar.openFromComponent(HttpErrorComponent, {
            data: httpError,
            verticalPosition: 'top',
            duration: 15000
        });
    }
}
