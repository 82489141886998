import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/';
import { UserService } from '../services/user.service';
import { DefinitionService, UserActionType } from '@craxit/crax-angular-core';

@Injectable()
export class RolePermissionGuard implements CanActivate {

    constructor(
        private router: Router,
        private userService: UserService,
        private definitioService: DefinitionService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const entityDef = this.definitioService.getEntityDefinitionByName(route.url[0].path);
        const actionType = route.url.toString().includes('create') ? UserActionType.Create : UserActionType.Read;

        if (this.userService.userHasPermissionFor(entityDef, actionType)) {
            return true;
        } else {
            this.router.navigate(['']);
            return false;
        }
    }
}
