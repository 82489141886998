import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Appsettings } from './models/appsettings';
import { DefinitionHttpService } from './services/definition-http.service';
import { CustomButtonService } from './services/custom-button.service';
import { StartupService, DefinitionService, LoggingService } from '@craxit/crax-angular-core';
import { Project, EntityDefinitionModel } from './Http/HttpClients';
import { CraxAuthService } from '@craxit/crax-angular-auth';
import { UserManagerSettings } from 'oidc-client';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  config: Appsettings;
  private url = 'assets/appsettings.json';
  private definitionUrl = '/api/Definition/Get';

  constructor(private httpClient: HttpClient,
    private definitionHttpService: DefinitionHttpService,
    private customButtonService: CustomButtonService,
    private startupService: StartupService,
    private definitionService: DefinitionService,
    private loggingService: LoggingService,
    private craxAuthService: CraxAuthService) { }

  public LoadAll(): Promise<boolean> {
    return new Promise((resolve) => {
      this.LoadSettings().then(() => {
        this.LoadAuthentication();
        this.LoadDefinitions().then(() => {
          this.customButtonService.Init();
          this.startupService.RunValidators();
          resolve(true);
        });
      });
    });
  }

  public LoadSettings(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<Appsettings>(this.url)
        .subscribe(
          (data: Appsettings) => {
            this.config = data;
            resolve(true);
          },
          (err) => {
            this.loggingService.logError('Load Settings Failed');
            this.loggingService.logError(err);
            reject(false);
          }
        );
    });
  }

  public LoadAuthentication(): void {
    const settings: UserManagerSettings = {
      client_id: this.config.Auth.ClientId,
      redirect_uri: this.config.Auth.RedirectUri,
      post_logout_redirect_uri: this.config.Auth.PostLogoutUri,
      authority: this.config.Auth.Authority,
      response_type: this.config.Auth.ResponseType,
      scope: this.config.Auth.Scope
    }
    this.craxAuthService.setClientSettings(settings);
  }

  public LoadDefinitions(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.InitDefinitions().then(() => {
        resolve(true);
      }, (err) => {
        this.loggingService.logError('Load Definitions Failed');
        this.loggingService.logError(err);
        reject(false);
      }
      );
    });
  }

  public InitDefinitions(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.httpClient.get<Project>(this.config.General.ApiUrl + this.definitionUrl).subscribe((project: Project) => {
        this.addProjectEntityDefinitions(project);
        this.addProjectViewEntityDefinitions(project);
        resolve(true);
      },
        (err) => {
          this.loggingService.logError('Init Definitions Failed');
          this.loggingService.logError(err);
          reject(false);
        }
      );

    });
  }

  private addProjectEntityDefinitions(project: Project): void {
    project.entityDefinitions.forEach(entityDefinitionModel => {
      this.definitionService.addEntityDefinition(this.definitionHttpService.mapEntity(entityDefinitionModel));
    })
  }

  private addProjectViewEntityDefinitions(project: Project): void {
    project.viewEntityDefinitions.forEach(viewEntityDefinitionModel => {
      this.definitionService.addEntityDefinition(this.definitionHttpService.mapViewEntity(viewEntityDefinitionModel, this.getEntityDefinitionFromProject(project, viewEntityDefinitionModel.definitionSource)));
    })
  }

  private getEntityDefinitionFromProject(project: Project, definitionSource: string): EntityDefinitionModel {
    return project.entityDefinitions.find(x => x.name.toLowerCase() === definitionSource.toLowerCase());
  }
}
