<mat-card class="register-form" *ngIf="this.craxFormGroup">
    <mat-card-content class="form-content">
        <h2>Registreer</h2>
        <crax-crud-form [craxFormGroup]="this.craxFormGroup">
        </crax-crud-form>
        <mat-card-actions class="sticky-footer">
            <button type="button" (click)="saveUser()"
                [disabled]="!this.craxFormGroup.valid || this.craxFormGroup.pristine" mat-raised-button button
                color="primary">Registreer</button>
        </mat-card-actions>
    </mat-card-content>
</mat-card>