import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DefinitionService, CraxAbstractHttpClient } from '@craxit/crax-angular-core';
import { DoorbirdConfigVM } from '../Http/HttpClients';

@Component({
  selector: 'app-doorbird-calendar',
  templateUrl: './doorbird-calendar.component.html',
  styleUrls: ['./doorbird-calendar.component.scss']
})
export class DoorbirdCalendarComponent {
  entityId: number;
  doorBirdConfig: DoorbirdConfigVM;

  constructor(private crudDataService: CraxAbstractHttpClient, private definitionService: DefinitionService, private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(params => {
      this.entityId = params.id;
      this.crudDataService.Get<DoorbirdConfigVM>(this.definitionService.getEntityDefinitionByName('doorbird'), this.entityId, 'DoorbirdSingleEvents, DoorbirdRecurringEvents').subscribe(doorbirdConfig => {
        this.doorBirdConfig = doorbirdConfig;
      });
    });
  }
}
