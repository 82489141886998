import { Component, OnInit, ChangeDetectionStrategy, Inject, ɵbypassSanitizationTrustResourceUrl, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL, Candidate, CandidateStatusType } from '../Http/HttpClients';
import { Observable, forkJoin } from 'rxjs';
import { CandidateActionDto, DailyPageDto } from '../candidate-action/candidate-action.component';
import { map } from 'rxjs/operators';
import { DefinitionService, EntityDefinition, EnumPropertyDefinition } from '@craxit/crax-angular-core';

export interface OData<T> {
    "@odata.count": number
    value: T[]
}

export interface Pagination {
    pageIndex: number;
    skip: number;
    take: number;
}

export interface EnumValue {
    value: string;
    display: string;
}

export type ParentType = 'ongoing-candidate' | 'hot-candidate' | 'open-candidate';

const DEFAULT_TAKE = 5;
const DEFAULT_DISPLAY_CANDIDATE_COLUMS = ['firstName', 'lastName', 'experience', 'status', 'candidateType', 'hardSkills', 'language', 'contactPreference', 'actions'];

@Component({
    selector: 'daily-page',
    templateUrl: './daily-page.component.html',
    styleUrls: ['./daily-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DailyPageComponent implements OnInit {
    constructor(private http: HttpClient, private defintionsService: DefinitionService, private changeRef: ChangeDetectorRef, @Inject(API_BASE_URL) baseUrl?: string) {
        this.baseUrl = baseUrl;
    }

    baseUrl: string = '';

    selectedCandidate: SelectedCandidate;

    onGoingCandidatesPagination: Pagination = { pageIndex: 0, skip: 0, take: DEFAULT_TAKE };
    hotCandidatesPagination: Pagination = { pageIndex: 0, skip: 0, take: DEFAULT_TAKE };
    openCandidatesPagination: Pagination = { pageIndex: 0, skip: 0, take: DEFAULT_TAKE };

    displayedColumns: string[] = DEFAULT_DISPLAY_CANDIDATE_COLUMS;
    candidateStatusEnumValues: EnumValue[] = [];
    candidateTypeEnumValues: EnumValue[] = [];

    onGoingCandidates$: Observable<OData<Candidate[]>>;
    hotCandidates$: Observable<OData<Candidate[]>>;
    openCandidates$: Observable<OData<Candidate[]>>;

    userId: number;


    ngOnInit(): void {
        this.fetchAllCandidates();
        this.fetchUserId();
        this.candidateStatusEnumValues = this.fetchEnumValues('candidate', 'status');
        this.candidateTypeEnumValues = this.fetchEnumValues('candidateevent', 'type');
    }

    fetchEnumValues(entityName: string, PropertyName: string) {
        const definition = this.defintionsService.getAllDefinitions().find(x => x.getName() === entityName);
        const enumValues = definition.properties.find(x => x.PropertyName === PropertyName) as EnumPropertyDefinition;
        return enumValues.EnumValues().map(({ display, value }) => ({ display, value }));
    }

    fetchAllCandidates(): void {
        this.fetchOnGoingCandidates();
        this.fetchHotCandidates();
        this.fetchOpenCandidates();
    }

    fetchOnGoingCandidates(): void {
        const { skip, take } = this.onGoingCandidatesPagination;
        this.onGoingCandidates$ = this.http.get<OData<Candidate[]>>(`${this.baseUrl}/odata/candidate?$count=true&$skip=${skip}&$top=${take}&$filter=status eq 'EersteGesprek'`);
    }

    fetchHotCandidates(): void {
        const { skip, take } = this.hotCandidatesPagination;
        this.hotCandidates$ = this.http.get<OData<Candidate[]>>(`${this.baseUrl}/odata/candidate?$count=true&$skip=${skip}&$top=${take}&$filter=status eq 'TweedeGesprek'`);
    }

    fetchOpenCandidates(): void {
        const { skip, take } = this.openCandidatesPagination;
        this.openCandidates$ = this.http.get<OData<Candidate[]>>(`${this.baseUrl}/odata/candidate?$count=true&$skip=${skip}&$top=${take}&$filter=status eq 'OpTeVolgen'`);
    }

    fetchUserId(): void {
        this.http.get<number>(`${this.baseUrl}/api/user/getuserid`).subscribe(x => {
            this.userId = x;
        });
    }


    onPaginatorChanged(parentType: ParentType, pageEvent: PageEvent): void {
        switch (parentType) {
            case 'ongoing-candidate': {
                this.onGoingCandidatesPagination = { ...this.calculatePagination(pageEvent) };
                this.fetchOnGoingCandidates();
                break;
            }
            case 'hot-candidate': {
                this.hotCandidatesPagination = { ...this.calculatePagination(pageEvent) };
                this.fetchHotCandidates();
                break;
            }
            case 'open-candidate': {
                this.openCandidatesPagination = { ...this.calculatePagination(pageEvent) };
                this.fetchOpenCandidates();
                break;
            }
        }
    }

    onRowClicked(selectedCandidate: SelectedCandidate): void {
        this.selectedCandidate = selectedCandidate;
    }

    calculatePagination({ pageIndex, pageSize }: PageEvent): Pagination {
        return {
            pageIndex,
            skip: pageIndex * pageSize,
            take: pageSize
        }
    }

    onFormSubmitted(dto: DailyPageDto | null): void {
        if (!dto) {
            this.selectedCandidate = null;
            return;
        }

        const { candidateAction, candidateStatus } = dto;
        const headers = { headers: { 'Content-Type': 'application/json' } };

        const $candidateEvent = this.http.post(`${this.baseUrl}/api/candidateevent/create`, {
            ...candidateAction,
            userId: this.userId,
        }, headers);

        const $candidateStatus = this.http.put(`${this.baseUrl}/api/candidate/updatecandidatestatus`, {
            candidateId: this.selectedCandidate.candidateId,
            status: candidateStatus,
        }, headers);

        forkJoin([$candidateEvent, $candidateStatus]).pipe(
            map(([createResponse, updateResponse]) => ({ createResponse, updateResponse }))
        ).subscribe(
            () => {
                this.fetchAllCandidates();
                this.selectedCandidate = null;
                this.changeRef.detectChanges();
            },
            (error) => {
                console.error('An error occurred:', error);
            }
        );
    }
}

export type SelectedCandidate = {
    candidateId: number;
    status: CandidateStatusType;
}
