<div class="jumbotron" *ngIf="userService.loggedInUser === null">
  <h1 class="display-4">Welcome to BookIT!</h1>
  <p class="lead">
    To proceed, please
    <button type="button" mat-button (click)="authService.startAuthentication()">Login</button>
    with your Crax IT account.
  </p>
</div>
<div class="logo-outer">
  <div class="logo">
    <img src="assets/logo_kleur-shadow_links.svg">
    <img src="assets/logo_kleur-shadow_rechts.svg">
  </div>
</div>
