import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { Client, API_BASE_URL } from './Http/HttpClients';
import { BwCrudTableComponent } from './bw-crud-table/bw-crud-table.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { AngularCraxFormsModule, CraxFormService, AbstractQueryService } from '@craxit/crax-angular-forms';
import * as moment from 'moment-timezone';
import { ContextMenuModule } from 'ngx-contextmenu';
import { GlobalErrorHandler } from './interceptors/GlobalErrorHandler';
import { CalendarModule, DateAdapter, DAYS_OF_WEEK, MOMENT, } from 'angular-calendar';
import localeNlBe from '@angular/common/locales/nl-BE';
import { RouterModule } from '@angular/router';
import { DoorbirdCalendarComponent } from './doorbird-calendar/doorbird-calendar.component';
import { BwCalendarDialogComponent } from './bw-calendar/bw-calendar-dialog/bw-calendar-dialog.component';
import { SettingsService } from './settings.service';
import { UserCalendarComponent } from './user-calendar/user-calendar.component';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { CraxAuthModule, JwtInterceptor } from '@craxit/crax-angular-auth';
import { AppComponent } from './app.component';
import { BwNavComponent } from './bw-nav/bw-nav.component';
import { BwCrudBaseformComponent } from './bw-crud-baseform/bw-crud-baseform.component';
import { BwRegisterFormComponent } from './bw-register-form/bw-register-form.component';
import { BwCalendarComponent } from './bw-calendar/bw-calendar.component';
import { BwLoginComponent } from './bw-login/bw-login.component';
import { AppRoutingModule } from './app-routing.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { UserService } from './services/user.service';
import { SnackBarService } from './services/snack-bar.service';
import { RequestInterceptor } from './interceptors/request-interceptor';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { RolePermissionGuard } from './guards/role-permission.guard';
// import { CraxFormService } from './crax-forms/crax-forms-services/CraxFormService';
import { AngularCraxCoreModule, LoggingService, CraxAbstractHttpClient, LoadingOverlayComponent } from '@craxit/crax-angular-core';
import { ErrorParseComponent } from './error-parse/error-parse.component';
import { AngularCraxTableModule } from '@craxit/crax-angular-table';
import { HttpCrudClient } from './services/http-crud-client.service';
import { HttpErrorComponent } from './http-error/http-error.component';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { CraxOdataService } from './services/crax-odata-service';
import { CraxSimpleTableComponent } from './crax-simple-table/crax-simple-table.component';
import { DailyPageComponent } from './static-pages/daily-page.component';
import { CandidateActionComponent } from './candidate-action/candidate-action.component';

registerLocaleData(localeNlBe, 'nl-BE');

moment.updateLocale('nl-BE', {

  week: {
    dow: DAYS_OF_WEEK.MONDAY,
    doy: 0
  }
});

// https://medium.com/@amcdnl/file-uploads-with-angular-reactive-forms-960fd0b34cb5
// https://medium.com/@amcdnl/angular-guards-more-than-security-c04be35adb00
@NgModule({
  declarations: [
    AppComponent,
    BwNavComponent,
    CraxSimpleTableComponent,
    BwCrudTableComponent,
    BwCrudBaseformComponent,
    BwRegisterFormComponent,
    BwCalendarComponent,
    BwCalendarDialogComponent,
    DoorbirdCalendarComponent,
    BwLoginComponent,
    UserCalendarComponent,
    // LoadingOverlayComponent,
    ErrorParseComponent,
    HttpErrorComponent,
    AuthCallbackComponent,
    DailyPageComponent,
    CandidateActionComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    HttpClientModule,
    MatToolbarModule,
    MatGridListModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSidenavModule,
    MatIconModule,
    MatSlideToggleModule,
    MatListModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatCardModule,
    MatCheckboxModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDialogModule,
    AngularCraxFormsModule,
    AngularCraxCoreModule,
    AngularCraxTableModule,
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: momentAdapterFactory
      },
    ),
    ContextMenuModule.forRoot({
      useBootstrap4: true
    }),
    CraxAuthModule,
    ClipboardModule,
  ],
  providers: [
    CanDeactivateGuard,
    RolePermissionGuard,
    Client,
    UserService,
    CraxFormService,
    { provide: APP_INITIALIZER, useFactory: InitSettings, deps: [SettingsService], multi: true },
    { provide: API_BASE_URL, useFactory: GetUrlFromSettings, deps: [SettingsService], multi: true },
    // { provide: API_BASE_URL, useFactory: GetUrlFromSettings, deps: [SettingsService], multi: true },

    // { provide: GetDefinitionService, useClass: DefinitionLoadingService },
    { provide: LOCALE_ID, useValue: 'nl-BE' },
    { provide: MAT_DATE_LOCALE, useValue: 'nl-be' },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    { provide: MOMENT, useValue: moment },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LoggingService, useClass: SnackBarService },
    { provide: CraxAbstractHttpClient, useClass: HttpCrudClient },
    { provide: AbstractQueryService, useClass: CraxOdataService },
    { provide: MAT_SNACK_BAR_DATA, useValue: [{}, {}] }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function InitSettings(settingsService: SettingsService): () => Promise<boolean> {
  return (): Promise<boolean> => settingsService.LoadAll();
}

export function GetUrlFromSettings(settingsService: SettingsService): string {
  return settingsService.config.General.ApiUrl;
}
export function momentAdapterFactory(): DateAdapter {
  return adapterFactory(moment);
}
