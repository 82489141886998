import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of, Subscription } from 'rxjs';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { CraxFormGroup, CraxFormArray, CraxFormService } from '@craxit/crax-angular-forms';
import { map, finalize, switchMap, take } from 'rxjs/operators';
import { CanComponentDeactivate } from '../guards/can-deactivate.guard';
import { LoadingOverlayService, DefinitionService, Entity, EntityDefinition, LoggingService, EntityTrackingState, CraxAbstractHttpClient, CraxSimpleDialogViewComponent, DialogData, DialogResponseType } from '@craxit/crax-angular-core';
import { SnackBarService } from '../services/snack-bar.service';

export interface BaseFormState {
  entityId?: number;
  entity?: Entity;
  craxFormGroup?: CraxFormGroup;
}
export interface EntityParams {
  entityId?: number;
  entityDefinition: EntityDefinition;
}
export interface AutoSave {
  enabled?: boolean;
  canSave?: boolean;
  maxDuration: number;
  currDuration: number;
}
@Component({
  selector: 'bw-crud-baseform',
  templateUrl: './bw-crud-baseform.component.html',
  styleUrls: ['./bw-crud-baseform.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BwCrudBaseformComponent implements OnInit, CanComponentDeactivate {
  crudFormIsValid(state: BaseFormState): boolean {
    return state.craxFormGroup.valid;
  }
  crudFormIsPristine(state: BaseFormState): boolean {
    return state.craxFormGroup.pristine;
  }
  crudFormErrors(state: BaseFormState): string[] {
    return this.findErrorsInForm(state.craxFormGroup).filter((v, i, a) => a.indexOf(v) === i);
  }

  public autoSave: AutoSave = { canSave: false, maxDuration: 40000, currDuration: 0 };
  public state: BaseFormState;
  private entityParams: EntityParams;
  constructor(private activatedRoute: ActivatedRoute,
    private router: Router,
    private loadingOverlayService: LoadingOverlayService,
    private loggingService: LoggingService,
    private definitionService: DefinitionService,
    private dialog: MatDialog,
    private formService: CraxFormService,
    private crudDataService: CraxAbstractHttpClient,
    private snackBarService: SnackBarService,
    private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.handleAutoSave();
    this.activatedRoute.params.pipe(
      switchMap((routeParams: Params) => {
        const entityParams: EntityParams = {
          entityId: routeParams.id,
          entityDefinition: this.definitionService.getEntityDefinitionByName(routeParams.entityName)
        };
        this.entityParams = entityParams
        return this.formService.getFormEntity(entityParams.entityDefinition, entityParams.entityId).pipe(
          map((entity: Entity) => {
            const craxFormGroup: CraxFormGroup = this.formService.createFormGroup(entityParams.entityDefinition, entity, true);
            craxFormGroup.monitorTrackingState();
            craxFormGroup.setPristineEntity(entity);
            return {
              entity,
              entityId: entityParams.entityId,
              craxFormGroup
            };
          })
        )
      })
    ).subscribe((state: BaseFormState) => {
      this.state = state;
      this.changeDetector.detectChanges()
    });
  }

  changeAutoSaveState() {
    this.autoSave.enabled = !this.autoSave.enabled;
    localStorage.setItem("autoSave", this.autoSave.enabled == true ? "true" : "false");
  }

  handleAutoSaveTimer(): void {
    this.autoSave.currDuration = this.autoSave.maxDuration * 0.001;
    let timerInterval = setInterval(() => {

      if (this.autoSave.enabled && this.state.craxFormGroup.dirty && this.findErrorsInForm(this.state.craxFormGroup).length == 0) {
        if (this.autoSave.currDuration > 1) {
          this.autoSave.currDuration--;
          this.autoSave.canSave = true;
        }
        else {
          this.insertOrUpdateForm(true);
          this.autoSave.currDuration = this.autoSave.maxDuration * 0.001;
        }
      }
      else
        this.autoSave.canSave = false;

      this.changeDetector.detectChanges();
    }, 1000);
  }

  handleAutoSave(): void {
    let autoSaveStatus = localStorage.getItem("autoSave");
    switch (autoSaveStatus) {
      case "true": this.autoSave.enabled = true; break;
      case "false": this.autoSave.enabled = false; break;
      default: {
        localStorage.setItem("autoSave", "true");
        break;
      }
    }
    this.handleAutoSaveTimer();
  }

  findErrorsInForm(form: CraxFormGroup | CraxFormArray): string[] {
    let errors: string[] = [];
    Object.keys(form.controls).forEach(field => {
      const control = form.get(field);
      if ((control instanceof CraxFormGroup && control.TrackingState !== EntityTrackingState.Deleted) || control instanceof CraxFormArray) {
        errors = errors.concat(this.findErrorsInForm(control));
        return;
      }
      if (control.errors) {
        errors.push(field);
      }
    });
    return errors;
  }

  updateTrackingStateOfDirtyControls(): void {
    Object.keys(this.state.craxFormGroup.controls).forEach(key => {
      const _key = this.state.craxFormGroup.get(key);
      const child = this.state.craxFormGroup.controls[key] as CraxFormArray;

      if (Array.isArray(_key.value)) {
        for (let i = 0; i < _key.value.length; i++) {
          if (!child && child.at(i).dirty && child.at(i).value.trackingState === "Unchanged") {
            child.at(i).get('trackingState').setValue("Modified");
          }
        }
      }
    })
  }

  insertOrUpdateForm(isAutoSave: boolean = false): void {
    this.updateTrackingStateOfDirtyControls();
    this.formService.insertOrUpdateForm(this.state.craxFormGroup).pipe(finalize(() => {
      if (!isAutoSave)
        this.loadingOverlayService.close();
    })).subscribe(entity => {
      this.state.craxFormGroup.setPristineEntity(entity);
      this.state.craxFormGroup.markAsPristine();

      if (!isAutoSave) {
        this.router.navigate([`/${this.state.craxFormGroup.entityDefinition.getName()}/${entity.id}`], { replaceUrl: true });
        this.loggingService.logMessage('Opgeslagen!', 'Success!');
      }
    })
  }

  SaveClick(): void {
    this.loadingOverlayService.open();
    this.insertOrUpdateForm();
  }

  DeleteClick(): void {
    const dialogConfig: MatDialogConfig<DialogData> = {
      width: '500px',
      data: {
        titleTxt: 'Bent u zeker dat u dit wilt verwijderen?',
        showConfirm: true,
        confirmTxt: 'Ja',
        cancelTxt: 'Nee',
      }
    };
    const dialogRef = this.dialog.open(CraxSimpleDialogViewComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result === DialogResponseType.Confirm)
        this.Delete();
    });
  }

  Delete(): void {
    this.crudDataService.Delete(this.entityParams.entityDefinition, this.entityParams.entityId)
      .subscribe(
        () => {
          this.loggingService.logMessage('Verwijderd!', 'Success!')
          this.router.navigate([`/${this.entityParams.entityDefinition.getName()}/list`], { replaceUrl: true });
        },
      );
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.state.craxFormGroup.pristine) {
      const dialogConfig: MatDialogConfig<DialogData> = {
        width: '500px',
        data: {
          titleTxt: 'Bent u zeker dat u deze pagina wilt verlaten zonder op te slaan?',
          showConfirm: true,
          confirmTxt: 'Ja',
          cancelTxt: 'Nee',
        }
      };
      const dialogRef: MatDialogRef<CraxSimpleDialogViewComponent, DialogResponseType> = this.dialog.open(CraxSimpleDialogViewComponent, dialogConfig);
      return dialogRef.afterClosed().pipe(map(result => {
        if (result === DialogResponseType.Cancel) {
          return false;
        }
        if (result === DialogResponseType.Confirm) {
          return true;
        }
      }));
    } else {
      return of(true);
    }
  }
}
