<div *ngIf="this.state.craxFormGroup">
  <h3 mat-dialog-title [ngStyle]="{'margin-top': '0px'}">
    {{this.dialogData.existingEntity ? this.dialogData.existingEntity.id ? "Update" : "Create" : "Create"}}
    {{this.dialogData.entityDefinition.getSingleTitle() | lowercase}}
  </h3>
  <div mat-dialog-content>
    <!-- <crax-crud-form [entityId]="existingEntity && existingEntity.id" [entityName]="entityDefinition.name"
    [propertyValue]="existingEntity">
  </crax-crud-form> -->
    <crax-crud-form [craxFormGroup]="this.state.craxFormGroup">
    </crax-crud-form>
  </div>
  <div mat-dialog-actions>
    <button type="submit" (click)='submit()' [disabled]="!this.state.craxFormGroup.valid" mat-raised-button button
      color="primary">Opslaan</button>
    <!-- [disabled]="!crudFormComponent.crudForm.valid || (existingEntity && existingEntity.id && crudFormComponent.crudForm.pristine)" -->

    <button type="button" mat-raised-button button (click)="cancel()" color="warn">Close</button>
  </div>
</div>
