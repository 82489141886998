import { ErrorHandler, Injectable } from '@angular/core';

// https://itnext.io/angular-6-7-jwt-authentication-tutorial-58c5d0682cd8
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor() { }
    // Angular defined error as an any type, so we have to keep it here
    // tslint:disable-next-line:no-any
    handleError(error: any): void {
        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
    }
}
@Injectable()
export class ErrorService {

}
