import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { Client } from '../Http/HttpClients';
import { CraxCrudFormComponent, CraxFormService, CraxFormGroup } from '@craxit/crax-angular-forms';
import { DefinitionService, LoggingService } from '@craxit/crax-angular-core';

@Component({
  selector: 'bw-register-form',
  templateUrl: './bw-register-form.component.html',
  styleUrls: ['./bw-register-form.component.scss']
})
export class BwRegisterFormComponent {

  craxFormGroup: CraxFormGroup;

  constructor(private httpClient: Client, private loggingService: LoggingService, private userService: UserService, definitionService: DefinitionService, formService: CraxFormService) {
    const def = definitionService.getEntityDefinitionByName('register');
    formService.getFormEntity(def).subscribe(entity => {
      this.craxFormGroup = formService.createFormGroup(def, entity, true);
    });
  }

  public saveUser = () => {
    const formvalues = this.craxFormGroup.getRawValue();
    this.httpClient.apiUserRegister(formvalues).subscribe(() => {
      this.loggingService.logMessage('Account geregistreerd!', 'Success!');
      this.userService.checkTokenStatus();
    }, err => {
      this.loggingService.logMessage(err.message, 'Error');
    });
  }
}
