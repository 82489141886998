import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BwCrudTableComponent } from './bw-crud-table/bw-crud-table.component';
import { BwCrudBaseformComponent } from './bw-crud-baseform/bw-crud-baseform.component';
import { BwCalendarComponent } from './bw-calendar/bw-calendar.component';
import { DoorbirdCalendarComponent } from './doorbird-calendar/doorbird-calendar.component';
import { BwRegisterFormComponent } from './bw-register-form/bw-register-form.component';
import { UserCalendarComponent } from './user-calendar/user-calendar.component';
import { BwLoginComponent } from './bw-login/bw-login.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { RolePermissionGuard } from './guards/role-permission.guard';
import { AuthGuard } from '@craxit/crax-angular-auth';
import { AuthCallbackComponent } from './auth-callback/auth-callback.component';
import { DailyPageComponent } from './static-pages/daily-page.component';

const routes: Routes = [
  { path: 'daily', component: DailyPageComponent, canActivate: [AuthGuard, RolePermissionGuard] },
  { path: ':entityName/list', component: BwCrudTableComponent, canActivate: [AuthGuard, RolePermissionGuard] },
  { path: ':entityName/create', component: BwCrudBaseformComponent, canActivate: [AuthGuard, RolePermissionGuard], canDeactivate: [CanDeactivateGuard] },
  { path: ':entityName/calendar', component: BwCalendarComponent, canActivate: [AuthGuard, RolePermissionGuard] },
  { path: 'doorbird/:id', component: DoorbirdCalendarComponent, canActivate: [AuthGuard, RolePermissionGuard] },
  { path: 'usercalendar/:id', component: UserCalendarComponent, canActivate: [AuthGuard, RolePermissionGuard] },
  {
    path: ':entityName/:id', component: BwCrudBaseformComponent, canActivate: [AuthGuard, RolePermissionGuard], canDeactivate: [CanDeactivateGuard]
  },
  { path: 'login', component: BwLoginComponent, canActivate: [AuthGuard] },
  { path: 'register', component: BwRegisterFormComponent },
  { path: 'doorcontrol', loadChildren: () => import('./db-control/db-control.module').then(m => m.DbControlModule), canActivate: [AuthGuard] },
  { path: '', component: BwLoginComponent },

  {
    path: 'auth-callback',
    component: AuthCallbackComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
