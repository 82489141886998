import { mergeMap as _observableMergeMap, catchError as _observableCatch, share, map } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from './HttpClients';
import { Entity, EntityTrackingState, HttpGetParams } from '@craxit/crax-angular-core';

@Injectable({
    providedIn: 'root'
})
export class ODataHttpClient {
    private http: HttpClient;
    private baseUrl: string;
    private ObservableCache = new Map<string, Observable<Entity>>();
    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
        this.http = http;
        this.baseUrl = baseUrl ? baseUrl : '';
    }

    getAll(httpGetParams: HttpGetParams): Observable<Entity> {

        httpGetParams.count = 'true'; // always requires count in odata calls for pagination
        const queryParamsUrl = Object.entries({ ...httpGetParams })
            .filter(([key, value]) => key !== 'entityDefinition' && value)
            .map(([key, value]: [string, string | number]) =>
                '$' + key + '=' + encodeURIComponent(value.toString().replace(':', ' '))).join('&');
        const fullUrl = this.baseUrl + '/odata/' + httpGetParams.entityDefinition.getName() + '?' + queryParamsUrl;
        const cachedObs = this.ObservableCache.get(fullUrl);
        if (cachedObs) {
            return cachedObs;
        }
        const obs = this.http.get<Array<Entity>>(fullUrl).pipe(share());
        this.ObservableCache.set(fullUrl, obs);
        return obs;
    }
}
