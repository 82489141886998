import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy, Output, EventEmitter, } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatRow } from '@angular/material/table';
import { Router } from '@angular/router';
import { DefinitionService } from '@craxit/crax-angular-core';
import { EnumValue, SelectedCandidate } from '../static-pages/daily-page.component';

@Component({
    selector: 'crax-simple-table',
    templateUrl: './crax-simple-table.component.html',
    styleUrls: ['./crax-simple-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class CraxSimpleTableComponent<T> implements OnInit {
    constructor(private router: Router, private definitionService: DefinitionService) { }

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    @Input()
    tableData: T[];
    @Input()
    dataCount: number;
    @Input()
    entityName: string;
    @Input()
    enumValues: EnumValue[];
    @Input()
    displayedColumns: string[];
    @Input()
    title: string;
    @Input()
    pageSize: number;
    @Input()
    pageIndex: number;
    @Output() paginatorEvent = new EventEmitter<PageEvent>();
    @Output() rowClickedEvent = new EventEmitter<SelectedCandidate>();

    translations: [string, string][];

    ngOnInit(): void {
        this.subscribeToPaginator();
        this.translations = this.definitionService
            .getEntityDefinitionByName('candidate').properties
            .filter(x => x.type !== 'hidden')
            .map(x => [x.PropertyName, x.tableOptions.Label] ?? [x.PropertyName, x.PropertyName]);
    }

    getHeaderName(propertyName: string): string {
        const prop = this.translations?.find(x => x[0] === propertyName);
        return propertyName === 'id' ? 'id' : prop ? prop[1] : propertyName;
    }

    subscribeToPaginator(): void {
        this.paginator.page.asObservable().subscribe(e => this.paginatorEvent.emit(e));
    }

    onNavigate(row: MatRow): void {
        this.router.navigateByUrl(`/candidate/${row['id']}`)
    }

    getColumnText(text: string): string {
        return this.enumValues.find(x => x.value === text)?.display ?? text;
    }


    onRowClicked(row: MatRow): void {
        this.rowClickedEvent.emit({
            candidateId: +row['id'],
            status: row['status'],
        })
    }
}
