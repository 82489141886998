<mat-card *ngIf="state !== undefined; else loadingError">
    <mat-card-content class="form-content">
        <crax-crud-form [craxFormGroup]="state.craxFormGroup">
        </crax-crud-form>
    </mat-card-content>
    <mat-card-actions class="sticky-footer">
        <button type="submit" (click)="SaveClick()" [disabled]="!crudFormIsValid(state) || crudFormIsPristine(state)"
            mat-raised-button button color="primary">
            Opslaan
        </button>
        <button type="button" *ngIf="state.entityId" (click)="DeleteClick()" mat-raised-button button
            color="warn">Delete</button>
        <button type="button" mat-button button [routerLink]="['../list']">Terug naar de lijst</button>
        <button type="button" mat-button color="warn" *ngIf="!crudFormIsValid(state)">
            Errors ({{crudFormErrors(state).length}}):
            {{crudFormErrors(state).join(", ")}}
        </button>
    </mat-card-actions>

    <mat-card-actions class="sticky-footer-right">
        <span *ngIf="autoSave.enabled" class="autosave-duration">
            {{autoSave.currDuration}}&nbsp;
        </span>
        <mat-slide-toggle [checked]="autoSave.enabled" (change)="changeAutoSaveState()"
            class="slider {{autoSave.canSave ? 'cansave' : 'cantsave'}}">
            Autosave
        </mat-slide-toggle>
    </mat-card-actions>
</mat-card>
<ng-template #loadingError>
    <h2>Loadingzzz</h2>
</ng-template>