<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav #sidenav class="sidenav" fixedInViewport="true" [attr.role]="(isHandset ) ? 'dialog' : 'navigation'"
    [mode]="(isHandset) ? 'over' : 'side'" [opened]="!(isHandset)" (click)="isHandset ? sidenav.toggle() : false"
    [hidden]="this.userTryGetIsNotFired || !this.userService.userRegistered">
    <mat-toolbar>Menu </mat-toolbar>
    <mat-nav-list *ngIf="!userTryGetIsLoading">
      <ng-container *ngFor="let navItem of navItems">
        <a mat-list-item (click)="onNavItemClicked(navItem)">
          {{navItem.getPluralTitle()}}
        </a>
      </ng-container>
      <a mat-list-item [routerLink]="'/doorcontrol'">Open door</a>
      <a mat-list-item [routerLink]="['usercalendar', userService.userId]">Calendar</a>
      <a mat-list-item [routerLink]="['youraccount', userService.userId]">Your Account</a>

      <a mat-list-item [matMenuTriggerFor]="queries" *ngIf="yourQueries.length > 0">Your Queries</a>
      <span class="spacer-nav-list"></span>
      <a mat-list-item class="logout-link" *ngIf="userService.userRegistered" (click)="logout()">Logout</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <mat-toolbar-row>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="sidenav.toggle()"
          *ngIf="userService.userRegistered && isHandset">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span>Bookit</span>
        <span class="fill-remaining-space"></span>
        <span style="position: relative">&nbsp;
          <ng-template [ngIf]="userService.userRegistered">Welcome {{userService.userName}}</ng-template>
          <div class="git-version">{{version}}</div>
        </span>
      </mat-toolbar-row>
    </mat-toolbar>

    <router-outlet *ngIf="!userTryGetIsLoading"></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>

<mat-menu #queries="matMenu" xPosition="after" class="query-menu">
  <button *ngFor="let query of yourQueries" mat-menu-item [routerLink]="'/' + query.entity + '/list'"
    [queryParams]="query.params" class="query-menu-item">
    <span>({{query.entity}}) {{query.title}}</span>
    <button mat-icon-button (click)="removeQuery($event, query)">
      <mat-icon>close</mat-icon>
    </button>
  </button>
</mat-menu>