import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UserCalendarVM } from '../Http/HttpClients';
import { DefinitionService, EntityDefinition, CraxAbstractHttpClient } from '@craxit/crax-angular-core';

@Component({
  selector: 'app-user-calendar',
  templateUrl: './user-calendar.component.html',
  styleUrls: ['./user-calendar.component.scss']
})
export class UserCalendarComponent {
  entityId: number;
  userCalendar: UserCalendarVM;
  userDefinition: EntityDefinition;

  constructor(private crudDataService: CraxAbstractHttpClient, private activatedRoute: ActivatedRoute, private definitionService: DefinitionService) {
    this.activatedRoute.params.subscribe(params => {
      this.entityId = params.id;
      this.userDefinition = this.definitionService.getEntityDefinitionByName('usercalendar');
      this.crudDataService.Get<UserCalendarVM>(
        this.userDefinition,
        this.entityId,
        this.userDefinition.getIncludesStringForCalendar()
      ).subscribe(x => {
        this.userCalendar = x;
      });
    });
  }
}
