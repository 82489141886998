import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { filter, take } from 'rxjs/operators';

import { IUpdateDate, IDialogData } from 'src/app/models/calendar/CalendarDialog';
import { CalendarEventType, Entity, EntityDefinition } from '@craxit/crax-angular-core';
import { EventMeta } from '../bw-calendar.component';


import { CraxCrudFormComponent, CraxFormService, CraxFormGroup } from '@craxit/crax-angular-forms';


export interface BwCalendarDialogComponentState {
  craxFormGroup?: CraxFormGroup;

}

@Component({
  selector: 'bw-calendar-dialog',
  templateUrl: './bw-calendar-dialog.component.html',
  styleUrls: ['./bw-calendar-dialog.component.scss']
})
export class BwCalendarDialogComponent {
  // @ViewChild(BwCrudFormComponent) crudFormComponent: BwCrudFormComponent;

  activeEventType: CalendarEventType = CalendarEventType.SingleEvent;
  state: BwCalendarDialogComponentState = {};
  dialogData: IDialogData;

  constructor(public dialogRef: MatDialogRef<BwCalendarDialogComponent, EventMeta>, @Inject(MAT_DIALOG_DATA) data: IDialogData, private formService: CraxFormService) {
    this.dialogData = data;
    // if (!data.existingEntity) {
    let entityId = data.existingEntity ? data.existingEntity.id : null;
    this.formService.getFormEntity(data.entityDefinition, entityId).subscribe(defaultEntity => {
      this.state.craxFormGroup = this.formService.createFormGroup(data.entityDefinition, defaultEntity, false);
      if (this.dialogData.updateDateAfterIsLoaded) {
        this.state.craxFormGroup.patchValue(this.dialogData.updateDateAfterIsLoaded);
      }
    });
    // }
    // else {
    //   this.state.craxFormGroup = this.formService.createFormGroup(data.entityDefinition, data.existingEntity, false);
    // }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.dialogData.parentId) {
      this.state.craxFormGroup.get(this.dialogData.entityDefinition.getCalendarOptions().parentIdPropertyName).setValue(this.dialogData.parentId);
    }
    this.formService.insertOrUpdateForm(this.state.craxFormGroup).subscribe(createdOrUpdatedItem => {
      this.dialogRef.close({ entity: createdOrUpdatedItem, entityDefinition: this.dialogData.entityDefinition });
    });
  }
}
