import { PropertyTypes, EntityDefinition, DefinitionService, PropertyDefinitionTypes, HttpGetParams, AutocompletePropertyDefinition, AutocompleteOptionsFromHttp, PageHelperService } from '@craxit/crax-angular-core';
import moment from 'moment';
import { Injectable } from '@angular/core';
import { AbstractQueryService, QueryParams, QueryLineCraxFormGroup, CraxBaseFormComponent } from '@craxit/crax-angular-forms';
import _ from 'lodash';

interface FilterSegmentValues { propertyName: string; filterValue: string; option: FilterOperations; }

@Injectable({
    providedIn: 'root'
})
export class CraxOdataService implements AbstractQueryService {

    constructor(private definitionService: DefinitionService, private pageHelperService: PageHelperService) { }

    public formatFilterParam(filters: QueryLineCraxFormGroup[]): string {
        let queryString = '';
        filters.forEach(filter => {
            if (filter.operator.value) {
                queryString += ` ${filter.operator.value} `;
            }

            const filterSegmentString = this.formatFilterParamOperation(filter);
            queryString += filterSegmentString;
        });
        return queryString;
    }

    private formatFilterParamOperation(filter: QueryLineCraxFormGroup): string {
        const propDef: PropertyDefinitionTypes = filter.propertyFormControl.value;
        const parentPropertyName: string = propDef.parentPropertyName
            ? propDef.parentPropertyName.replace(/\./g, '/')
            : '';

        const propName = `${parentPropertyName}${parentPropertyName.length > 0 ? '/' : ''}${propDef.PropertyName}`;
        const option = filter.option.value;
        const isDateType: boolean = propDef?.type === PropertyTypes.DateType;
        const isHiddenDateType: boolean = propDef?.type === PropertyTypes.HiddenType &&
            propDef?.CapitalizedPropertyName.toLowerCase()?.includes('date');
        const isRelativeDate: boolean = option === FilterOperations.previous || option === FilterOperations.next;

        let value = filter.filterValue.value;
        if ((isDateType || isHiddenDateType) && !isRelativeDate) {
            value = moment(value).format('YYYY-MM-DD');
        }
        const valueIsNotString = propDef?.PropertyType === PropertyTypes.DateType
            || propDef?.PropertyType === PropertyTypes.CheckboxType
            || propDef?.PropertyType === PropertyTypes.HiddenType
            || propDef?.PropertyType === PropertyTypes.NumberType;

        switch (option) {
            case FilterOperations.contains:
                return `contains(${propName},'${value}')`;
            case FilterOperations.notContains:
                return `contains(${propName},'${value}') eq false`;
            case FilterOperations.equals:
                return valueIsNotString ? `${propName} eq ${value}` : `${propName} eq '${value}'`;
            case FilterOperations.notEquals:
                return valueIsNotString ? `${propName} ne ${value}` : `${propName} ne '${value}'`;
            case FilterOperations.lesserThan:
            case FilterOperations.beforeDate:
                return `${propName} lt ${value}`;
            case FilterOperations.greaterThan:
            case FilterOperations.afterDate:
                return `${propName} gt ${value}`;
            case FilterOperations.equalsLesserThan:
            case FilterOperations.untilDate:
                return `${propName} le ${value}`;
            case FilterOperations.equalsGreaterThan:
            case FilterOperations.fromDate:
                return `${propName} ge ${value}`;
            case FilterOperations.previous:
                return `previous(${propName},${value})`;
            case FilterOperations.next:
                return `next(${propName},${value})`
        }
    }

    public getAutocompleteFilterString(control: CraxBaseFormComponent<AutocompletePropertyDefinition>, autocompleteOptionsFromHttp: AutocompleteOptionsFromHttp, searchValue: string): string {
        let filterString: string;

        if (searchValue === '' && (this.pageHelperService.PageType === 'detail' && autocompleteOptionsFromHttp.staticFilter && control.getControlFromParentForm(autocompleteOptionsFromHttp.staticFilter.valueField)))
            return `${autocompleteOptionsFromHttp.staticFilter.queryField} eq ${control.getValueFromParentForm(autocompleteOptionsFromHttp.staticFilter.valueField)}`;
        else if (searchValue === '')
            return autocompleteOptionsFromHttp.filters.map(f => `contains(${f},'${searchValue}')`).join(' or ');

        if (searchValue.includes(' '))
            filterString = this.getSpacedAutoCompleteFilterString(autocompleteOptionsFromHttp, searchValue);
        else
            filterString = autocompleteOptionsFromHttp.filters.map(f => `contains(${f},'${searchValue}')`).join(' or ');

        if (this.pageHelperService.PageType === 'detail' && autocompleteOptionsFromHttp.staticFilter && control.getControlFromParentForm(autocompleteOptionsFromHttp.staticFilter.valueField)) { // Gets triggered @ CompanyAction => contact
            filterString = `(${filterString}) and ${autocompleteOptionsFromHttp.staticFilter.queryField} eq ${control.getValueFromParentForm(autocompleteOptionsFromHttp.staticFilter.valueField)}`;
        }
        return filterString;
    }

    public getAutocompleteExpandString(control: CraxBaseFormComponent<AutocompletePropertyDefinition>, autocompleteOptionsFromHttp: AutocompleteOptionsFromHttp): string {
        let expandString: string;

        autocompleteOptionsFromHttp.filters.forEach(f => {
            if (expandString)
                expandString += ',';
            if (f.includes('/'))
                expandString = f.substr(0, f.indexOf('/'));
        });

        return expandString;
    }

    public getQueryString(filterValue: string, propDef: PropertyDefinitionTypes): string {
        switch (propDef.type) {
            case PropertyTypes.DateType:
                return `${propDef.PropertyName} eq  ${moment(filterValue, 'DD/MM/YYYY').format('YYYY-MM-DD')}`;
            case PropertyTypes.OneType:
                return `(${propDef.FilterProperties.map(filterProperty => `contains(${propDef.PropertyName}/${filterProperty},'${filterValue}')`).join(' or ')})`;
            case PropertyTypes.EnumType:
                return `${propDef.PropertyName} eq '${filterValue}'`;
            case PropertyTypes.CheckboxType:
                return `${propDef.PropertyName} eq ${filterValue}`;
            default:
                return `contains(${propDef.PropertyName},'${filterValue}')`;
        }
    }

    public convertToQueryLineCFGs(filterString: string, entityDef: EntityDefinition): QueryLineCraxFormGroup[] {
        const filterStrSegments = this.getFilterSegmentsFromFilterString(filterString);
        const filterCFGs: QueryLineCraxFormGroup[] = filterStrSegments.map<QueryLineCraxFormGroup>(filterStrSegment => {
            const filterCFG = this.convertToQueryLineCFG(filterStrSegment, entityDef);
            return filterCFG;
        });

        return filterCFGs;
    }

    private getFilterSegmentsFromFilterString(filterString: string): string[] {
        if (filterString == null) {
            return [];
        } else {
            const filterStrSegments: string[] = filterString.replace(/ and /g, '|and ').replace(/ or /g, '|or ').split('|');
            return filterStrSegments;
        }
    }
    private convertToQueryLineCFG(filterStringSegment: string, entityDef: EntityDefinition): QueryLineCraxFormGroup {
        const entityDefCopy: EntityDefinition = _.cloneDeep(entityDef);
        // Extract operaotr (and || or)
        let operator = '';
        if (filterStringSegment.startsWith('and ')) {
            operator = 'and';
            filterStringSegment = filterStringSegment.slice('and '.length);
        } else if (filterStringSegment.startsWith('or ')) {
            operator = 'or';
            filterStringSegment = filterStringSegment.slice('or '.length);
        }

        // Extract other values
        let filterSegmentValues: FilterSegmentValues;
        if (filterStringSegment.includes('contains(')
            && filterStringSegment.includes(') eq false')
        ) {
            filterSegmentValues = this.extractNotContainsSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes('contains(')) {
            filterSegmentValues = this.extractContainsSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes(' eq ')) {
            filterSegmentValues = this.extractEqualsSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes(' ne ')) {
            filterSegmentValues = this.extractNotEqualsSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes(' lt ')) {
            filterSegmentValues = this.extractLesserThanSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes(' le ')) {
            filterSegmentValues = this.extractEqualsLesserThanSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes(' gt ')) {
            filterSegmentValues = this.extractGreaterThanSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes(' ge ')) {
            filterSegmentValues = this.extractEqualsGreaterThanSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes('next(')) {
            filterSegmentValues = this.extractNextSegmentValues(filterStringSegment);
        } else if (filterStringSegment.includes('previous(')) {
            filterSegmentValues = this.extractPreviousSegmentValues(filterStringSegment);
        } else {
            throw new Error('Unknown filter line: ' + filterStringSegment);
        }

        const propertyType = this.findPropertyDefinition(filterSegmentValues.propertyName, entityDefCopy);

        const filter = new QueryLineCraxFormGroup(entityDefCopy, false, null);
        filter.propertyName.setValue(filterSegmentValues.propertyName);
        filter.propertyFormControl.setValue(propertyType);
        filter.option.setValue(filterSegmentValues.option);
        filter.filterValue.setValue(filterSegmentValues.filterValue);
        filter.operator.setValue(operator);

        return filter;
    }

    private extractNotContainsSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const option = FilterOperations.notContains;
        const propertyName = /(?<=contains\()(.*)(?=,)/.exec(filterStringSegment)[0];
        const filterValue = /(?<=,')(.*)(?='\) eq false)/.exec(filterStringSegment)[0];
        return { option, propertyName, filterValue };
    }

    private extractContainsSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const option = FilterOperations.contains;
        const propertyName = /(?<=contains\()(.*)(?=,)/.exec(filterStringSegment)[0];
        const filterValue = /(?<=,')(.*)(?='\))/.exec(filterStringSegment)[0];
        return { option, propertyName, filterValue };
    }

    private extractEqualsSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const option = FilterOperations.equals;
        const propertyName = /(.*)(?= eq )/.exec(filterStringSegment)[0];

        let filterValue = /(?<= eq )(.*)/.exec(filterStringSegment)[0];
        if (filterValue.startsWith('\'') && filterValue.endsWith('\'')) { // value can have quotes around it
            filterValue = filterValue.slice(1, filterValue.length - 1);
        }

        return { option, propertyName, filterValue };
    }

    private extractNotEqualsSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const option = FilterOperations.notEquals;
        const propertyName = /(.*)(?= ne )/.exec(filterStringSegment)[0];
        let filterValue = /(?<= ne )(.*)/.exec(filterStringSegment)[0];
        if (filterValue.startsWith('\'') && filterValue.endsWith('\'')) { // value can have quotes around it
            filterValue = filterValue.slice(1, filterValue.length - 1);
        } return { option, propertyName, filterValue };
    }

    private extractLesserThanSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const propertyName = /(.*)(?= lt )/.exec(filterStringSegment)[0];
        const filterValue = /(?<= lt )(.*)/.exec(filterStringSegment)[0];
        const option = this.isDate(filterValue) ? FilterOperations.beforeDate : FilterOperations.lesserThan;
        return { option, propertyName, filterValue };
    }

    private extractEqualsLesserThanSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const propertyName = /(.*)(?= le )/.exec(filterStringSegment)[0];
        const filterValue = /(?<= le )(.*)/.exec(filterStringSegment)[0];
        const option = this.isDate(filterValue) ? FilterOperations.untilDate : FilterOperations.equalsLesserThan;
        return { option, propertyName, filterValue };
    }

    private extractGreaterThanSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const propertyName = /(.*)(?= gt )/.exec(filterStringSegment)[0];
        const filterValue = /(?<= gt )(.*)/.exec(filterStringSegment)[0];
        const option = this.isDate(filterValue) ? FilterOperations.afterDate : FilterOperations.greaterThan;
        return { option, propertyName, filterValue };
    }

    private extractEqualsGreaterThanSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const propertyName = /(.*)(?= ge )/.exec(filterStringSegment)[0];
        const filterValue = /(?<= ge )(.*)/.exec(filterStringSegment)[0];
        const option = this.isDate(filterValue) ? FilterOperations.fromDate : FilterOperations.equalsGreaterThan;
        return { option, propertyName, filterValue };
    }

    private extractNextSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const option = FilterOperations.next;
        const propertyName = /(?<=next\()(.*)(?=,)/.exec(filterStringSegment)[0];
        const filterValue = /(?<=,)(.*)(?=\))/.exec(filterStringSegment)[0];
        return { option, propertyName, filterValue };
    }

    private extractPreviousSegmentValues(filterStringSegment: string): FilterSegmentValues {
        const option = FilterOperations.previous;
        const propertyName = /(?<=previous\()(.*)(?=,)/.exec(filterStringSegment)[0];
        const filterValue = /(?<=,)(.*)(?=\))/.exec(filterStringSegment)[0];
        return { option, propertyName, filterValue };
    }

    private findPropertyDefinition(propertyPathName: string, entityDefenition: EntityDefinition): PropertyDefinitionTypes {
        if (!propertyPathName.includes('/')) {
            return entityDefenition.getPropertyByName(propertyPathName);
        } else {
            const nestedProperties: string[] = propertyPathName.split('/');
            return this.findNestedPropertyDefinition(nestedProperties, entityDefenition);

        }
    }

    private findNestedPropertyDefinition(nestedProperties: string[], rootDefinition: EntityDefinition): PropertyDefinitionTypes {
        let definition: EntityDefinition = _.cloneDeep(this.definitionService.getEntityDefinitionByName(nestedProperties[0]));
        let parent: string = definition.getSingleTitle();
        let parentPropertyName: string = rootDefinition.getPropertyByName(nestedProperties[0]).PropertyName;

        for (let index = 1; index < nestedProperties.length; index++) {
            const property: PropertyDefinitionTypes = definition.getPropertyByName(nestedProperties[index]);

            if (index === nestedProperties.length - 1) {
                property.parent = parent;
                property.parentPropertyName = parentPropertyName;
                return property;
            }

            parentPropertyName += `.${definition.getPropertyByName(nestedProperties[index]).PropertyName}`;
            definition = _.cloneDeep(this.definitionService.getEntityDefinitionByName(nestedProperties[index]));
            parent += `.${definition.getSingleTitle()}`;
        }
    }

    private getSpacedAutoCompleteFilterString(autocompleteOptionsFromHttp: AutocompleteOptionsFromHttp, searchValue: string): string {
        let filterString = '';
        searchValue.split(' ').forEach(v => {
            if (v !== ' ' && v !== '') {
                if (filterString !== '')
                    filterString += ' and ';
                filterString += `(${autocompleteOptionsFromHttp.filters.map(f => `contains(${f},'${v}')`).join(' or ')})`
            }
        });

        filterString += ` or ${autocompleteOptionsFromHttp.filters.map(f => `contains(${f},'${searchValue}')`).join(' or ')}`;
        return filterString + ' ';
    }

    private isDate(value: string): boolean {
        const regex = new RegExp("([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})");
        if (!regex.test(value)) {
            return false;
        }
        var date = moment(value);
        if (!date.isValid()) {
            return false;
        }
        return true;
    }
}

export enum FilterOperations {
    equals = 'Gelijk aan',
    notEquals = 'Niet gelijk aan',
    contains = 'Bevat',
    notContains = 'Bevat niet',
    lesserThan = 'Kleiner dan',
    greaterThan = 'Groter dan',
    equalsLesserThan = 'Is kleiner dan (≤)',
    equalsGreaterThan = 'Is groter dan (≥)',
    fromDate = 'vanaf',
    afterDate = 'na',
    untilDate = 'tot en met',
    beforeDate = 'voor',
    previous = 'afgelopen',
    next = 'komende'
}
