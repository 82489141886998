import { Component, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-http-error',
  templateUrl: './http-error.component.html',
  styleUrls: ['./http-error.component.scss']
})
export class HttpErrorComponent {
  copyButton = {
    icon: 'library_add',
    text: 'copy'
  };
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: HttpErrorResponse,
    private _snackBarRef: MatSnackBarRef<HttpErrorComponent>,
    private clipboard: Clipboard
  ) {
  }

  dismissSnack() {
    this._snackBarRef.dismiss();
  }

  displayCorrectMessage(errorResponse: HttpErrorResponse): string {
    if (errorResponse.status) {
      switch (errorResponse.status) {
        case 400:
          return errorResponse.error;
        case 401:
          return 'Oops, 401 you are not loggedin.';
        case 403:
          return 'Oops, 403 you are not allowed to see this content.';
        case 404:
          return 'Oops, 404 not found.';
        default:
          return 'Oops, something went wrong with the connection.';
      }
    } else {
      return errorResponse.message;
    }
  }

  async copyToClipboard(errorResponse: HttpErrorResponse) {
    const displayObj = errorResponse as any;
    if (errorResponse.error) {
      displayObj.error = errorResponse.error;
    }
    const textForClipboard = Object.keys(displayObj).reduce((clipText, key) => clipText += `${key}: ${(displayObj)[key]}\n`);
    const pending = this.clipboard.beginCopy(textForClipboard)
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        console.log("clip board failing");
        setTimeout(attempt);
      } else {
        this.copyButton.icon = 'library_add_check';
        this.copyButton.text = 'copied';
        // Remember to destroy when you're done!
        pending.destroy();
      }
    }
    attempt();
  }

}
