<mat-card>
  <mat-card-content class="form-content">
    <mat-grid-list cols="3" rowHeight="55px">
      <mat-grid-tile>
        <button type="button" mat-raised-button button [(viewDate)]="viewDate"
          (viewDateChange)="viewDate = $event;calendarViewChanged()" color="primary" mwlCalendarPreviousView
          [view]="view">Vorige</button>
        <button type="button" mat-raised-button button [(viewDate)]="viewDate"
          (viewDateChange)="viewDate = $event;calendarViewChanged()" mwlCalendarToday>Vandaag</button>
        <button type="button" mat-raised-button button [(viewDate)]="viewDate"
          (viewDateChange)="viewDate = $event;calendarViewChanged()" color="primary" mwlCalendarNextView
          [view]="view">Volgende</button>

        <mat-menu [hasBackdrop]="true" #appMenu="matMenu">
          <div mat-menu-item
            *ngFor="let calendarEntityDefinition of permittedCalendarEvents(calendarEntityDefinitions)">
            <mat-checkbox
              (change)="$event.checked ? toggleEntityOff(calendarEntityDefinition.getName()): toggleEntityOn(calendarEntityDefinition.getName())"
              [checked]="true" (click)="$event.stopPropagation()">
              {{calendarEntityDefinition.getSingleTitle()}}</mat-checkbox>
          </div>
        </mat-menu>
        <button type="button" mat-raised-button button color="primary" [matMenuTriggerFor]="appMenu">
          Categorieën
        </button>

      </mat-grid-tile>
      <mat-grid-tile>
        <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):locale | titlecase }}</h3>
      </mat-grid-tile>
      <mat-grid-tile>
        <button type="button" mat-raised-button button
          [class.mat-primary]="view === CalendarView.Month ? 'primary' : ''"
          (click)="view = CalendarView.Month;calendarViewChanged()">Maand</button>
        <button type="button" mat-raised-button button [class.mat-primary]="view === CalendarView.Week ? 'primary' : ''"
          (click)="view = CalendarView.Week;calendarViewChanged()">Week</button>
        <button type="button" mat-raised-button button [class.mat-primary]="view === CalendarView.Day ? 'primary' : ''"
          (click)="view = CalendarView.Day;calendarViewChanged()">Dag</button>
      </mat-grid-tile>
    </mat-grid-list>
    <div [ngSwitch]="view">

      <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="calendarEvents"
        [refresh]="refresh" (eventClicked)="eventClicked($event)" [locale]="locale"
        [headerTemplate]="monthHeaderTemplate" [activeDayIsOpen]="activeDayIsOpen" [cellTemplate]="monthCellTemplate"
        (dayClicked)="toggleEventListsInMonthView($event.day)" [openDayEventsTemplate]="monthOpenEventsTemplate">
      </mwl-calendar-month-view>

      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/month/calendar-month-view-header.component.ts -->
      <ng-template #monthHeaderTemplate let-days="days" let-locale="locale">
        <div class="cal-cell-row cal-header">
          <div class="cal-cell" *ngFor="let day of days;" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday"
            [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend" [ngClass]="day.cssClass">
            {{ day.date | calendarDate:'monthViewColumnHeader':locale | titlecase }}
          </div>
        </div>
      </ng-template>

      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/month/calendar-month-cell.component.ts -->
      <ng-template #monthCellTemplate let-day="day" let-openDay="openDay" let-locale="locale"
        let-tooltipPlacement="tooltipPlacement" let-highlightDay="highlightDay" let-unhighlightDay="unhighlightDay"
        let-eventClicked="eventClicked">
        <div class="fill-height" [contextMenu]="basicMenu" [contextMenuSubject]="day.date">
          <div class="cal-cell-top">
            <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span>
            <span class="cal-day-number">{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span>
          </div>
          <div class="cal-events">
            <div class="cal-event" *ngFor="let event of day.events.slice(0,5);let i = index"
              [mwlCalendarTooltip]="event.title | calendarEventTitle:'monthTooltip':event"
              [tooltipPlacement]="tooltipPlacement"
              (click)="$event.stopPropagation(); eventClicked.emit({event: event})">
              <span [ngClass]="{'event-is-completed': monthCellViewIsEventCompletedOrPassed(event)}">
                {{event.title}}
              </span>
            </div>

            <span *ngIf="day.events.length > 5">
              <small>+ {{day.events.length  - 5 }} item{{(day.events.length-5) > 1 ? "s" : ""}}</small>
            </span>

          </div>
        </div>
      </ng-template>
      <ng-template #monthOpenEventsTemplate let-events="events" let-eventClicked="eventClicked" let-isOpen="isOpen">
        <div class="cal-open-day-events" [@collapse] *ngIf="isOpen">
          <div *ngFor="let event of events" [ngClass]="event?.cssClass">
            <ng-container [ngTemplateOutlet]="deleteEventTemplate" [ngTemplateOutletContext]="{event : event}">
            </ng-container>
            &ngsp;
            <mwl-calendar-event-title [ngClass]="{'event-is-completed': monthCellViewIsEventCompletedOrPassed(event)}"
              [event]="event" view="month" (mwlClick)="eventClicked.emit({event: event})">
            </mwl-calendar-event-title>
          </div>
        </div>
      </ng-template>

      <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="calendarEvents"
        [refresh]="refresh" (eventClicked)="eventClicked($event)" [locale]="locale"
        [headerTemplate]="weekHeaderTemplate" [hourSegmentTemplate]="weekHourSegmentTemplate"
        [eventTemplate]="weekEventTemplate" [dayStartHour]="dayStartHour" [dayEndHour]="dayEndHour">
      </mwl-calendar-week-view>

      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/week/calendar-week-view-hour-segment.component.ts -->
      <ng-template #weekHourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
        let-isTimeLabel="isTimeLabel">
        <div class="cal-hour-segment" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart"
          [class.cal-after-hour-start]="!segment.isStart" [ngClass]="segment.cssClass" [contextMenu]="basicMenu"
          [contextMenuSubject]="segment.date">
          <div class="cal-time" *ngIf="isTimeLabel">
            {{segment.date |date : 'H:mm':locale}}
          </div>
        </div>
      </ng-template>

      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/week/calendar-week-view-header.component.ts -->
      <ng-template #weekHeaderTemplate let-days="days" let-locale="locale" let-dayHeaderClicked="dayHeaderClicked">
        <div class="cal-day-headers">
          <div class="cal-header" *ngFor="let day of days" [class.cal-past]="day.isPast" [class.cal-today]="day.isToday"
            [class.cal-future]="day.isFuture" [class.cal-weekend]="day.isWeekend"
            (click)="dayHeaderClicked.emit({day: day})" [contextMenu]="basicMenu" [contextMenuSubject]="day.date">
            <b>{{ day.date | calendarDate:'weekViewColumnHeader':locale | titlecase }}</b><br>
            <span>{{ day.date | calendarDate:'weekViewColumnSubHeader':locale }}</span>
          </div>
        </div>
      </ng-template>

      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/week/calendar-week-view-event.component.ts -->
      <ng-template #weekEventTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
        let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
        let-tooltipAppendToBody="tooltipAppendToBody" let-tooltipDisabled="tooltipDisabled">
        <div class="cal-event" [style.backgroundColor]="weekEvent.event.color?.secondary" [style.width]="'auto'"
          [style.borderColor]="weekEvent.event.color?.primary"
          [mwlCalendarTooltip]="!tooltipDisabled ? (weekEvent.event.title | calendarEventTitle:'weekTooltip':weekEvent.event) : ''"
          [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="weekEvent.event" [tooltipTemplate]="tooltipTemplate"
          [tooltipAppendToBody]="tooltipAppendToBody" (mwlClick)="eventClicked.emit()">

          <ng-container [ngTemplateOutlet]="deleteEventTemplate" [ngTemplateOutletContext]="{event : weekEvent.event}">
          </ng-container>
          &ngsp;
          <mwl-calendar-event-title [event]="weekEvent.event" view="week">
          </mwl-calendar-event-title>
        </div>
      </ng-template>

      <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="calendarEvents"
        [refresh]="refresh" (eventClicked)="eventClicked($event)" [locale]="locale"
        [hourSegmentTemplate]="dayHourSegmentTemplate" [eventTemplate]="dayEventTemplate" [dayStartHour]="dayStartHour"
        [dayEndHour]="dayEndHour">
      </mwl-calendar-day-view>

      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/day/calendar-day-view-hour-segment.component.ts -->
      <ng-template #dayHourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight">
        <div class="cal-hour-segment" [style.height.px]="segmentHeight" [class.cal-hour-start]="segment.isStart"
          [class.cal-after-hour-start]="!segment.isStart" [ngClass]="segment.cssClass" [contextMenu]="basicMenu"
          [contextMenuSubject]="segment.date">
          <div class="cal-time">
            {{segment.date | date : 'H:mm' : locale}}
          </div>
        </div>
      </ng-template>
      <!-- https://github.com/mattlewis92/angular-calendar/blob/a5fe1f975ecf332c96797326f48081e5d02d264d/projects/angular-calendar/src/modules/day/calendar-day-view-event.component.ts -->
      <ng-template #dayEventTemplate let-dayEvent="dayEvent" let-tooltipPlacement="tooltipPlacement"
        let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate"
        let-tooltipAppendToBody="tooltipAppendToBody">
        <div class="cal-event" [style.backgroundColor]="dayEvent.event.color?.secondary"
          [style.borderColor]="dayEvent.event.color?.primary"
          [mwlCalendarTooltip]="dayEvent.event.title | calendarEventTitle:'dayTooltip':dayEvent.event"
          [tooltipPlacement]="tooltipPlacement" [tooltipEvent]="dayEvent.event" [tooltipTemplate]="tooltipTemplate"
          [tooltipAppendToBody]="tooltipAppendToBody" (mwlClick)="eventClicked.emit()">
          <ng-container [ngTemplateOutlet]="deleteEventTemplate" [ngTemplateOutletContext]="{event : dayEvent.event}">
          </ng-container>
          <mwl-calendar-event-title [event]="dayEvent.event" view="day">
          </mwl-calendar-event-title>
        </div>
      </ng-template>

      <ng-template let-event="event" #deleteEventTemplate>
        <span (mwlClick)="deleteEventConfirmed(event)">
          <mat-icon
            [ngStyle]="{'cursor':'pointer','font-size': '16px','height':'16px','width':'16px','vertical-align':'sub'}">
            clear</mat-icon>
        </span>
      </ng-template>

      <context-menu #basicMenu>
        <ng-template *ngFor="let calendarEntityDefinition of permittedCalendarEvents(calendarEntityDefinitions)"
          contextMenuItem (execute)="addEvent($event.item,calendarEntityDefinition)">
          {{calendarEntityDefinition.getSingleTitle()}}
        </ng-template>
      </context-menu>
    </div>
  </mat-card-content>
</mat-card>