import { Injectable } from '@angular/core';
import { ButtonService, CraxFormGroup } from '@craxit/crax-angular-forms';

/**
 *
 * Adds functions to the ButtonService, so that they may be called from within a definition
 * @export
 * CustomButtonService
 */
@Injectable({
  providedIn: 'root'
})
export class CustomButtonService {

  constructor(
    private buttonService: ButtonService,
  ) { }

  /**
   *
   * Adds functions to the ButtonService, so that they may be called from within a definition
   */
  Init(): void {
    this.buttonService.AddUrlAction('goToMap', this.goToMap.bind(this));
  }

  /**
   *
   * Creates a url that points to the craxFormGroup Address in Google Maps
   * @returns Google Maps url
   */
  public goToMap(craxFormGroup: CraxFormGroup): string {
    const address = craxFormGroup.value['street'] + '+' + craxFormGroup.value['houseNumber'] + ',+' + craxFormGroup.value['postalCode'] + '+' + craxFormGroup.value['city'] + '+' + craxFormGroup.value['country'];
    const googleMapsBaseUrl = 'https://www.google.com/maps/place/';

    return googleMapsBaseUrl + address;
  }
}
