<div class="snackContainer" *ngIf="data">
  <p>
    {{this.displayCorrectMessage(data)}}
  </p>
  <span class="spacer"></span>
  <button color="primary" mat-raised-button (click)="copyToClipboard(data)">
    <mat-icon>{{copyButton.icon}}</mat-icon>
    {{copyButton.text}}
  </button>
  <button mat-button (click)="dismissSnack()">
    <mat-icon>close</mat-icon>
  </button>
</div>
