<div class="header-card">
    <mat-card-title>
        {{ title }}
    </mat-card-title>
</div>
<div [ngStyle]="{ 'overflow-x': 'auto' }">
    <mat-table class="mat-elevation-z8" [dataSource]="tableData">

        <ng-container *ngFor="let column of displayedColumns.slice(0, displayedColumns.length - 1)"
            [matColumnDef]="column">
            <ng-container>
                <mat-header-cell *matHeaderCellDef>{{ column }}</mat-header-cell>
                <mat-cell *matCellDef="let row">{{ getColumnText(row[column]) }}</mat-cell>
            </ng-container>
        </ng-container>


        <!-- 'Actions' column -->
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row;">
                <button mat-icon-button (click)="onNavigate(row)">
                    <mat-icon>launch</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns" (click)="onRowClicked(row)"></mat-row>

    </mat-table>
    <mat-paginator #paginator [length]="dataCount" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 20, 50, 100]">
    </mat-paginator>
</div>