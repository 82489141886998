import { Component, OnInit } from '@angular/core';
import { CraxAuthService as AuthService } from '@craxit/crax-angular-auth';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-auth-callback',
  templateUrl: './auth-callback.component.html',
  styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router, private userService: UserService) {
  }

  async ngOnInit() {
    await this.authService.completeAuthentication();
    await this.userService.checkTokenStatus();
    this.router.navigate(['']);
  }

}
