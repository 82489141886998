import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Client } from '../Http/HttpClients';
import { StringHelperService, HttpGetParams, Entity, AutocompleteOptionsFromHttp, EntityDefinition, DefinitionHttpOptionsEnum, DefinitionHttpOptions, CraxAbstractHttpClient } from '@craxit/crax-angular-core';
import { ODataHttpClient } from '../Http/ODataHttpClients';
import { map } from 'rxjs/operators';
import moment from 'moment';
@Injectable({
  providedIn: 'root'
})
export class HttpCrudClient implements CraxAbstractHttpClient {

  constructor(private odataClient: ODataHttpClient, private stringHelperService: StringHelperService, private httpClient: Client) { }

  Count(httpGetParams: HttpGetParams): Observable<number> {
    // return this.odataClient.getAll(entityDefinition.getName(), '', filter).pipe(map(x => x['@odata.count']));
    return this.odataClient.getAll(httpGetParams).pipe(map(x => x['@odata.count']));
  }
  GetAll(httpGetParams: HttpGetParams): Observable<Entity[]> {
    return this.odataClient.getAll(httpGetParams).pipe(map(x => (x.value as Entity[]).map(entity => this.datesToMoment(entity))));
  }

  GetDefaultItem(entityDefinition: EntityDefinition): Observable<Entity> {
    return this.httpClient[this.functionNameCreator(entityDefinition.getName(), DefinitionHttpOptionsEnum.GetDefaultItem, entityDefinition.getHttpOptions())]();
  }

  Get<T = Entity>(entityDefinition: EntityDefinition, id?: number | null | undefined, includes?: string | null | undefined): Observable<T> {
    return this.httpClient[this.functionNameCreator(entityDefinition.getName(), DefinitionHttpOptionsEnum.Get, entityDefinition.getHttpOptions())](id, includes);
  }

  GetAllPaged(entityDefinition: EntityDefinition, orderBy?: string | null | undefined, filter?: string | null | undefined, skip?: number | null | undefined, take?: number | null | undefined, includes?: string | null | undefined): Observable<Entity[]> {
    return this.httpClient[this.functionNameCreator(entityDefinition.getName(), DefinitionHttpOptionsEnum.GetAll, entityDefinition.getHttpOptions())](orderBy, filter, skip, take, includes);
  }

  GetAllAutocomplete(autocompleteOptions: AutocompleteOptionsFromHttp, orderBy?: string | null | undefined, filter?: string | null | undefined, skip?: number | null | undefined, take?: number | null | undefined, includes?: string | null | undefined): Observable<Entity[]> {
    return this.httpClient[this.functionNameCreator(autocompleteOptions.entityName, DefinitionHttpOptionsEnum.GetAll, autocompleteOptions.httpOptions)](orderBy, filter, skip, take, includes);
  }

  Create(entityDefinition: EntityDefinition, entity: Entity): Observable<Entity> {
    return this.httpClient[this.functionNameCreator(entityDefinition.getName(), DefinitionHttpOptionsEnum.Create, entityDefinition.getHttpOptions())](entity);
  }

  Update(entityDefinition: EntityDefinition, entity: Entity): Observable<Entity> {
    return this.httpClient[this.functionNameCreator(entityDefinition.getName(), DefinitionHttpOptionsEnum.Update, entityDefinition.getHttpOptions())](entity);
  }

  Delete(entityDefinition: EntityDefinition, id: number): Observable<Entity> {
    return this.httpClient[this.functionNameCreator(entityDefinition.getName(), DefinitionHttpOptionsEnum.Delete, entityDefinition.getHttpOptions())](id);
  }

  private functionNameCreator(entityName: string, httpOption: DefinitionHttpOptionsEnum, httpOptions: DefinitionHttpOptions): string {
    let url = 'api';

    if (httpOptions && httpOptions[httpOption]) {
      url += httpOptions[httpOption].split('/').map(part => this.stringHelperService.capitalize(part.toLowerCase())).join('');

    } else {
      url += this.stringHelperService.capitalize(entityName.toLowerCase()) + this.stringHelperService.capitalize(httpOption.toLowerCase());
    }

    if (url.endsWith('Get'))
      url = url.replace('Get', 'Getbyid');

    return url;
  }

  private capitalizeFunctionname(name: string) {
    if (name.indexOf('reminder') >= 0) name = name.replace('reminder', 'Reminder');

    return "apiCandidateReminderGetbyid";
  }

  private datesToMoment(entity: any): any {
    var validator = new RegExp('([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})T([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2}).([0-9]{0,10})Z');
    var systemDateValidator = new RegExp('([0-9]{4})-([0-9]{1,2})-([0-9]{1,2})T([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2}).([0-9]{0,10})\\+([0-9]{1,2}):([0-9]{1,2})');
    for (const key of new Set([...Object.keys(entity)])) {
      if (key.toLocaleLowerCase().includes("date") && entity[key]) {
        if (validator.test(entity[key].toString())) {
          entity[key] = moment(entity[key].toString());
        }
        if (systemDateValidator.test(entity[key].toString())) {
          entity[key] = moment(entity[key].toString());
        }
      }
    }
    return entity;
  }
}
