import { Component, OnInit, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-error-parse',
  templateUrl: './error-parse.component.html',
  styleUrls: ['./error-parse.component.scss']
})
export class ErrorParseComponent implements OnInit {
  public config: ErrorParseConfig;
  public responseText: string;

  constructor(
    public snackBarRef: MatSnackBarRef<ErrorParseComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: [HttpErrorResponse, ErrorParseConfig]) {
  }

  ngOnInit(): void {
    this.responseText = this.getStatusCodeMessage(this.data[0].status);
  }

  private getStatusCodeMessage(statusCode: number): string {
    switch (statusCode) {
      case 400:
        return this.data[0].message;
      case 403:
        return this.responseText = 'FORBIDDEN';
      case 500:
        return this.responseText = 'INTERNAL SERVER ERROR';
      default:
        return this.responseText = 'Unknown status';
    }
  }

}

export class ErrorParseConfig {
  showAll?: boolean;
  showFullMessage?: boolean;
  // add more props for custom configuring
}
