import { Component, OnInit, ViewChild, Input, ChangeDetectionStrategy, } from '@angular/core';
import { DefinitionService, EntityDefinition, Entity, LoggingService } from '@craxit/crax-angular-core';
import { MatPaginator } from '@angular/material/paginator';
import { CraxFormGroup, CraxFormService } from '@craxit/crax-angular-forms';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'bw-crud-table',
    templateUrl: './bw-crud-table.component.html',
    styleUrls: ['./bw-crud-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BwCrudTableComponent implements OnInit {
    entityDefinitionMap: Map<String, EntityDefinition>
    entityDefinition: EntityDefinition;
    filterCFG: CraxFormGroup;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(
        private definitionService: DefinitionService,
        private craxFormService: CraxFormService,
        private activatedRoute: ActivatedRoute,
        private logService: LoggingService
    ) {
        this.entityDefinitionMap = this.definitionService.getEntityDefinitionMap();
        this.entityDefinition = this.definitionService.getEntityDefinitionByName(this.activatedRoute.snapshot.params.entityName);

        this.filterCFG = this.craxFormService.createFormGroup(this.entityDefinition, {} as Entity, false);
    }
    ngOnInit() {
    }

    public onQuerySaved(queryTitle: any): void {
        this.logService.logMessage('Query saved', `The query '${queryTitle}' was saved`);
    }

}
